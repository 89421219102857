interface D2gRoundOptions {
    currency?: boolean;
    override?: [boolean, number];
    returnString?: boolean;
}

export const d2gRound = (
    number: number,
    options: D2gRoundOptions = {}
): string | number => {
    // Destructure options with default values
    const {
        currency = false,
        override = [false, 1],
        returnString = false
    } = options;

    let rounded: number | string;

    if (typeof number === 'number') {
        rounded = number;
    } else {
        rounded = parseFloat(number);
    }

    if (override[0]) {
        rounded = parseFloat(rounded.toFixed(override[1]));
    } else if (currency) {
        if (returnString) {
            rounded = rounded.toFixed(2);
        } else {
            rounded = parseFloat(rounded.toFixed(2));
        }
    } else if (Math.abs(rounded) < 10) {
        rounded = parseFloat(rounded.toFixed(2));
    } else if (Math.abs(rounded) < 100) {
        rounded = parseFloat(rounded.toFixed(1));
    } else {
        if (returnString) {
            rounded = Math.round(rounded).toLocaleString(undefined, { maximumFractionDigits: 0 });
        } else {
            rounded = Math.round(rounded);
        }
    }

    if (returnString) {
        rounded = rounded.toString();
    }

    return rounded;
}

export function getInvertedBivariateColorBreaks(
    maxX: number, minX: number, recordCountX: number, dataX: any,
    maxY: number, minY: number, recordCountY: number, dataY: any,
    allData: any, bivariateIndicator: string, activeIndicator: string, 
  ) {
    console.log("D070824 recordCountX, minX", recordCountX, minX);
    console.log("D070824 recordCountY, minY", recordCountY, minY);
    let xInd = bivariateIndicator;
    let yInd = activeIndicator;
    recordCountX = recordCountX - minX;
    recordCountY = recordCountY - minY;
  
    // Calculate the quantiles for the breaks
    const _quantileX1 = allData.dataArray[xInd][Math.ceil(recordCountX / 3)];
    const _quantileX2 = allData.dataArray[xInd][Math.ceil((recordCountX / 3) * 2)];
    const _quantileY1 = allData.dataArray[yInd][Math.ceil(recordCountY / 3)];
    const _quantileY2 = allData.dataArray[yInd][Math.ceil((recordCountY / 3) * 2)];

    console.log("D070824 recordCountX", recordCountX);
    console.log("D070824 recordCountY", recordCountY);
    console.log("D070824 allData.dataArray[yInd]", allData.dataArray[xInd]);
    console.log("D070824 allData.dataArray[yInd]", allData.dataArray[yInd]);
    console.log("D070824 _quantileX1", _quantileX1);
    console.log("D070824 _quantileX2", _quantileX2);
    console.log("D070824 _quantileY1", _quantileY1);
    console.log("D070824 _quantileY2", _quantileY2);

    return {
      xBreaks: [_quantileX1?.value ? _quantileX1?.value : null, _quantileX2?.value ? _quantileX2?.value : null], // Breaks for the X axis (value)
      yBreaks: [_quantileY1?.value ? _quantileY1?.value : null, _quantileY2?.value ? _quantileY2?.value : null]  // Breaks for the Y axis (value2)
    };
  }

const PUMA_to_CD: { [key: string]: string[] } = {
'3604103':['103'],
'3604104':['104'],
'3604107':['107'],
'3604108':['108'],
'3604109':['109'],
'3604110':['110'],
'3604111':['111'],
'3604112':['112'],
'3604121':['101','102'],
'3604165':['105','106'],
'3604204':['204'],
'3604205':['205'],
'3604207':['207'],
'3604208':['208'],
'3604209':['209'],
'3604210':['210'],
'3604211':['211'],
'3604212':['212'],
'3604221':['201','202'],
'3604263':['203','206'],
'3604301':['301'],
'3604302':['302'],
'3604303':['303'],
'3604304':['304'],
'3604305':['305'],
'3604306':['306'],
'3604307':['307'],
'3604308':['308'],
'3604309':['309'],
'3604310':['310'],
'3604311':['311'],
'3604312':['312'],
'3604313':['313'],
'3604314':['314'],
'3604315':['315'],
'3604316':['316'],
'3604317':['317'],
'3604318':['318'],
'3604401':['401'],
'3604402':['402'],
'3604403':['403'],
'3604404':['404'],
'3604405':['405'],
'3604406':['406'],
'3604407':['407'],
'3604408':['408'],
'3604409':['409'],
'3604410':['410'],
'3604411':['411'],
'3604412':['412'],
'3604413':['413'],
'3604414':['414'],
'3604501':['501'],
'3604502':['502'],
'3604503':['503'],
}

const CD_to_PUMA = {
'103':'3604103',
'104':'3604104',
'107':'3604107',
'108':'3604108',
'109':'3604109',
'110':'3604110',
'111':'3604111',
'112':'3604112',
'101':'3604121',
'102':'3604121',
'105':'3604165',
'106':'3604165',
'204':'3604204',
'205':'3604205',
'207':'3604207',
'208':'3604208',
'209':'3604209',
'210':'3604210',
'211':'3604211',
'212':'3604212',
'201':'3604221',
'202':'3604221',
'203':'3604263',
'206':'3604263',
'301':'3604301',
'302':'3604302',
'303':'3604303',
'304':'3604304',
'305':'3604305',
'306':'3604306',
'307':'3604307',
'308':'3604308',
'309':'3604309',
'310':'3604310',
'311':'3604311',
'312':'3604312',
'313':'3604313',
'314':'3604314',
'315':'3604315',
'316':'3604316',
'317':'3604317',
'318':'3604318',
'401':'3604401',
'402':'3604402',
'403':'3604403',
'404':'3604404',
'405':'3604405',
'406':'3604406',
'407':'3604407',
'408':'3604408',
'409':'3604409',
'410':'3604410',
'411':'3604411',
'412':'3604412',
'413':'3604413',
'414':'3604414',
'501':'3604501',
'502':'3604502',
'503':'3604503',
}

export function crosswalkPUMAtoCD(puma: string) {
    //console.log("081624 puma", puma);
    //console.log("081624 PUMA_to_CD", PUMA_to_CD);
    //console.log("081624 PUMA_to_CD[puma]", PUMA_to_CD[puma]);
    //console.log("081624 CD_to_PUMA", CD_to_PUMA);
    return PUMA_to_CD[puma];
  }