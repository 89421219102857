import React, { useState, useEffect, useRef } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';

export interface PyramidChartProps {
  options: any;
  data: any;
  width: number; // This parameter will be ignored
  height: number;
  colorClass:string;
}

const Pyramid: React.FC<PyramidChartProps> = ({ options, data, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [ageGroups, setAgeGroups] = useState<any>([]); // Added state for age groups
  const [barHeight, setBarHeight] = useState<number>(20); // Adjusted height for bars
  const [maxCategoryValue, setMaxCategoryValue] = useState<number>(0);
  const selectedId = useGlobalSelectedId();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const combinedData: any[] = [];
      const _filteredData = options.variables.filter((v: any) => {
        return v.sort.Tooltip_only === "";
      });

      // Group by age and maintain separate male and female values
      const ageGroupsArray = Array.from(new Set(_filteredData.map((v: any) => v.label.replace(/(Females|Males|\(%\))/g, '').trim())));
      console.log("090224 ageGroupsArray", ageGroupsArray);
      const under5Index = ageGroupsArray.indexOf("Under 5");
      const fiveTo14Index = ageGroupsArray.indexOf("5-14");

      if (under5Index !== -1) {
        ageGroupsArray.splice(under5Index, 1);
      }
      if (fiveTo14Index !== -1) {
        ageGroupsArray.splice(fiveTo14Index > under5Index ? fiveTo14Index - 1 : fiveTo14Index, 1);
      }

      // Insert "Under 5" and "5-14" at the start of the array
      ageGroupsArray.unshift("Under 5", "5-14");
      setAgeGroups(ageGroupsArray); // Store age groups in state
      
      ageGroupsArray.forEach((ageGroup) => {
        const femaleData = _filteredData.find((v: any) => v.label.includes('Females') && v.label.includes(ageGroup));
        const maleData = _filteredData.find((v: any) => v.label.includes('Males') && v.label.includes(ageGroup));
        
        if (femaleData) {
          combinedData.push({
            name: ageGroup,
            value: data.dataJSON[selectedId][femaleData.variable],
            category: 'Female',
            variable: femaleData.variable,
          });
        }
        if (maleData) {
          combinedData.push({
            name: ageGroup,
            value: data.dataJSON[selectedId][maleData.variable],
            category: 'Male',
            variable: maleData.variable,
          });
        }
      });

      setPreppedData(combinedData);
      setMaxCategoryValue(Math.max(...combinedData.map((d: any) => d.value)));
    }
  };

  // Use percentages for bar widths
  const barScale = (value: number) => (value / maxCategoryValue) * 50; // Scale to 50% for each side

  const onClicked = (variable: string) => {
    onSetActiveIndicator(variable);
  };

  const fontSize2 = 12; 

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', paddingRight: "16px", position: 'relative' }}>
      {/* Left Info Section */}
      <div 
        style={{ 
          marginLeft: '0px', 
          marginRight: '0px', 
          fontSize: fontSize2, 
          lineHeight: `${fontSize2 + 2}px`, 
          width: '250px', 
        }}>
        <p style={{ marginRight: "15px" }}>
          Other information about this stat including the year of origin and relevant notes and maybe an assessment of how typical this percentage is.
          <br /><span style={{ color: '#ccc' }}>Source: Stats United</span>
        </p>
      </div>
      
      {/* Pyramid Section */}
      <div 
        style={{ 
          marginRight: '0px', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          width: 'calc(100% - 400px)', 
          position: 'relative',
        }}
      >
        {/* Continuous Divider Line */}
        <div style={{ 
          width: '2px', 
          height: `${(ageGroups.length * (barHeight + 10) - 10)}px`, 
          backgroundColor: 'black', 
          position: 'absolute', 
          left: '50%', 
          transform: 'translateX(-50%)' 
        }}></div>
        <div style={{ 
          width: '100%', 
          height: `${(ageGroups.length * (barHeight + 10)) - 10}px`, 
          backgroundColor: 'none', 
          position: 'absolute', 
          left: '0', 
          //transform: 'translateX(-50%)' 
        }}>
          <div style={{
            position: 'absolute',
            left: '10%',
            top: '50%',
            transform: 'translate(-50%, -50%) rotate(-90deg)',
            fontSize: `${fontSize2}px`,
            fontWeight: 'bold'
          }}>Female</div>
          <div style={{
            position: 'absolute',
            right: '10%',
            top: '50%',
            transform: 'translate(50%, -50%) rotate(90deg)',
            fontSize: `${fontSize2}px`,
            fontWeight: 'bold'
          }}>Male</div>
        </div>  
        {ageGroups.map((ageGroup:string, i:number) => {
          const femaleData = preppedData.find((d: any) => d.category === 'Female' && d.name === ageGroup);
          const maleData = preppedData.find((d: any) => d.category === 'Male' && d.name === ageGroup);
          return (
            <div key={i} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', position: 'relative', width: '100%' }}>
              {/* Wrapper for Female Bar */}
              <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: `${barHeight}px` }}>
                <div 
                  style={{ 
                    height: '100%', 
                    width: `${barScale(femaleData?.value || 0)}%`, // Percentage width for responsiveness
                    backgroundColor: Colors.getColorQuintile(femaleData?.value, 0, maxCategoryValue, colorClass), 
                    cursor: 'pointer', 
                  }}
                  onClick={() => onClicked(femaleData?.variable)}
                ></div>
              </div>

              {/* Wrapper for Male Bar */}
              <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: `${barHeight}px` }}>
                <div 
                  style={{ 
                    height: '100%', 
                    width: `${barScale(maleData?.value || 0)}%`, // Percentage width for responsiveness
                    backgroundColor: Colors.getColorQuintile(maleData?.value, 0, maxCategoryValue, colorClass), 
                    cursor: 'pointer', 
                  }}
                  onClick={() => onClicked(maleData?.variable)}
                ></div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Right Labels Section */}
      <div style={{ width: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: `${fontSize2}px` }}>
        {ageGroups.map((ageGroup:string, i:number) => {
          const femaleData = preppedData.find((d: any) => d.category === 'Female' && d.name === ageGroup);
          const maleData = preppedData.find((d: any) => d.category === 'Male' && d.name === ageGroup);
          return (
            <div key={i} style={{ width: '100%', display: 'flex', alignItems: 'center', height: `${barHeight + 10}px` }}>
              <div style={{ width: '50%', textAlign: 'left' }}>
                <strong>{d2gRound(femaleData?.value + maleData?.value)}</strong>
              </div>
              <div style={{ width: '50%', textAlign: 'left', paddingLeft: '5px' }}>
                {ageGroup}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Pyramid;
