import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { DataProvider } from './data/DataContext';
import StatExplorer from './views/StatExplorer';
import Dashboards from './views/Dashboards';
import Comparisons from './views/Comparisons';
import MoALogo from './assets/icons/MoALogo.svg';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStatusStoreNavigation } from './data/StatusStore'; // Correct import path
import SearchPopup from './components/structure/SearchPopup'; // Correct import path
import { useData, FlatFileCsvData} from './data/DataContext';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalActiveGeography } from './data/StatusStore';

interface NavigationProps {
  indicatorOptions: any | null,
  groupedModules: any | null,
  allData: any | null,
  setAllData: (data: any) => void,
  setGroupedModules: (data: any) => void,
  setIndicatorOptions: (data: any) => void,
}

function Navigation({ indicatorOptions, groupedModules, allData, setAllData, setIndicatorOptions, setGroupedModules }: NavigationProps) {
  const navigate = useNavigate();
  const location = useLocation();

  // Initialize the StatusStore with navigation and location
  useStatusStoreNavigation();

  // Extract page from URL search parameters
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || 'explorer';
  const { 
    dataActive, dataTract, dataPUMA, dataNYC, dataCD, dataBoro, isLoadingSorter,
    isLoadingTract, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, isLoadingAllTabularData, dataSorter,
    error } = useData();

  const activeGeography = useGlobalActiveGeography();

  // Log the current page for debugging
  useEffect(() => {
    console.log("Current page:", currentPage);
  }, [currentPage]);

  useEffect(() => {
    console.log("C092524 allData", allData);
  }, [allData]);
  useEffect(() => {
    console.log("092924 dataActive", dataActive);
  }, [dataActive]);

  useEffect(() => {
    //if (!isLoadingBoro && !isLoadingCD && !isLoadingNYC && !isLoadingPUMA && !isLoadingTract) {
    if (!isLoadingAllTabularData) {
        if (dataBoro && dataCD && dataNYC && dataPUMA && dataTract) {
            console.log("dataBoro", dataBoro);
            console.log("dataCD", dataCD);
            console.log("dataNYC", dataNYC);
            console.log("dataPUMA", dataPUMA);
            console.log("B092324 dataTract", dataTract);
            let _indicatorOptions = [] as any;
            /*let _excludeOptions = ["", "DISPLAY_NAME"] as any;
            let testLimit = 0; 
            Object.keys(dataTract.meta.VARIABLE).forEach((i: string) => {
                //if (testLimit < 3){
                    if (!_excludeOptions.includes(dataTract.meta.DISPLAY_NAME[i])){
                        _indicatorOptions.push(
                            { label: dataTract.meta.DISPLAY_NAME[i], value: i, type:"swarm" }
                        )
                        _indicatorOptions.push(
                            { label: dataTract.meta.DISPLAY_NAME[i], value: i, type:"histogram" }
                        )
                        testLimit++;
                    }
                //}
                
            })*/
            setAllData({
                "Boro": dataBoro,
                "CD": dataCD,
                "NYC": dataNYC,
                "PUMA": dataPUMA,
                "Tract": dataTract
            });
            //setIndicatorOptions(_indicatorOptions)
        }
    }
}, [/*dataBoro, isLoadingBoro, dataCD, isLoadingCD, dataNYC, isLoadingNYC, dataPUMA, isLoadingPUMA, dataTract, isLoadingTract,*/ isLoadingAllTabularData]);

useEffect(() => {
    console.log("092924 dataActive", dataActive);
    console.log("092924 isLoadingAllTabularData, isLoadingBoro, isLoadingCD, isLoadingNYC, isLoadingPUMA, isLoadingTract, isLoadingSorter", isLoadingAllTabularData, isLoadingBoro, isLoadingCD, isLoadingNYC, isLoadingPUMA, isLoadingTract, isLoadingSorter);
    console.log("092924 dataActive, dataSorter, Object.keys(dataActive).length > 0", dataActive, dataSorter, Object.keys(dataActive ? dataActive : {}).length > 0);
    if (!isLoadingAllTabularData) { 
    //if (!isLoadingBoro && !isLoadingCD && !isLoadingNYC && !isLoadingPUMA && !isLoadingTract && !isLoadingSorter) { 
        if (dataActive && dataSorter && Object.keys(dataActive).length > 0) {
            let _indicatorOptions = [] as any;
            let _classifiedIndicators = {} as any;
            //console.log("092924 dataSorter", dataSorter);
            //console.log("092924 activeGeography", activeGeography);
            
            const groupedData: any = {};
            dataSorter.forEach((data:any) => {
                if (data.Module === "Point Data"){
                  return;
                }
                //console.log("C092524 data", data);
                //console.log("C092524 data[activeGeography]", data[activeGeography]);
                //console.log("C092524 data['Category 1']", data["Category 1"]);
                //console.log("C092524 data['SubCategory 1']", data["SubCategory 1"]);
                let category = null;
                let subCategory = null;
                if (data[activeGeography] !== undefined && data[activeGeography] !== null){
                    if (data[activeGeography] !== "X"){
                        category = data["Category 1"];
                        subCategory = data["SubCategory 1"];
                    }else{
                      //console.log("D092524 data[activeGeography]", data[activeGeography], data);
                    }
                }
                //console.log("C092524 category", category);
                //console.log("C092524 subCategory", subCategory);
                if (category && subCategory){
                  if (!data.Module || !data.Shape) {
                    //console.log("092924 data", data)
                  }
                  const module = data.Module || "Unknown";
                  const shape = data.Shape || "Unknown";
                  let sumTo100 = data["Sums to 100"] 
                      ? data["Sums to 100"] !== "Ungrouped" 
                          ? data["Sums to 100"]
                          : data["Sums to 100"] 
                      : "Ungrouped";
                  sumTo100 = shape.toLowerCase() === "pyramid" ? "Ungrouped" : sumTo100;
                  if (!groupedData[category]) {
                    groupedData[category] = {};
                  }
              
                  if (!groupedData[category][subCategory]) {
                    groupedData[category][subCategory] = {};
                  }
              
                  if (!groupedData[category][subCategory][module]) {
                    groupedData[category][subCategory][module] = {};
                  }
              
                  if (!groupedData[category][subCategory][module][sumTo100]) {
                    groupedData[category][subCategory][module][sumTo100] = {};
                  }
                  if (!groupedData[category][subCategory][module][sumTo100][shape]) {
                    groupedData[category][subCategory][module][sumTo100][shape] = [];
                  }
                  groupedData[category][subCategory][module][sumTo100][shape].push(data);
                }
            });
            dataSorter.forEach((data:any) => {
              if (data.Module === "Point Data"){
                return;
              }
              let category = null;
              let subCategory = null;
              if (data[activeGeography] !== undefined && data[activeGeography] !== null){
                  if (data[activeGeography] !== "X"){
                      category = data["Category 2"];
                      subCategory = data["SubCategory 2"];
                  }else{

                  }
              }
                if (category && subCategory){
                    if (category !== "" && subCategory !== ""){
                        if (!data.Module || !data.Shape) {
                          //console.log("092924 data", data)
                        }
                        const module = data.Module || "Unknown";
                        const shape = data.Shape || "Unknown";
                        let sumTo100 = data["Sums to 100"] 
                            ? data["Sums to 100"] !== "Ungrouped" 
                                ? data["Sums to 100"]
                                : data["Sums to 100"] 
                            : "Ungrouped";
                        sumTo100 = shape.toLowerCase() === "pyramid" ? "Ungrouped" : sumTo100;
                        if (!groupedData[category]) {
                        groupedData[category] = {};
                        }
                    
                        if (!groupedData[category][subCategory]) {
                        groupedData[category][subCategory] = {};
                        }
                    
                        if (!groupedData[category][subCategory][module]) {
                        groupedData[category][subCategory][module] = {};
                        }
                    
                        if (!groupedData[category][subCategory][module][sumTo100]) {
                        groupedData[category][subCategory][module][sumTo100] = {};
                        }
                        if (!groupedData[category][subCategory][module][sumTo100][shape]) {
                        groupedData[category][subCategory][module][sumTo100][shape] = [];
                        }
                        groupedData[category][subCategory][module][sumTo100][shape].push(data);
                    }
                }
            });
            dataSorter.forEach((data:any) => {
              if (data.Module === "Point Data"){
                return;
              }
              let category = null;
              let subCategory = null;
              if (data[activeGeography] !== undefined && data[activeGeography] !== null){
                  if (data[activeGeography] !== "X"){
                      category = data["Category 3"];
                      subCategory = data["SubCategory 3"];
                  }
              }
                if (category && subCategory){
                    if (category !== "" && subCategory !== ""){
                        if (!data.Module || !data.Shape) {
                          //console.log("092924 data", data)
                        }
                        const module = data.Module || "Unknown";
                        const shape = data.Shape || "Unknown";
                        let sumTo100 = data["Sums to 100"] 
                            ? data["Sums to 100"] !== "Ungrouped" 
                                ? data["Sums to 100"]
                                : data["Sums to 100"] 
                            : "Ungrouped";
                        sumTo100 = shape.toLowerCase() === "pyramid" ? "Ungrouped" : sumTo100;
                        if (!groupedData[category]) {
                        groupedData[category] = {};
                        }
                    
                        if (!groupedData[category][subCategory]) {
                        groupedData[category][subCategory] = {};
                        }
                    
                        if (!groupedData[category][subCategory][module]) {
                        groupedData[category][subCategory][module] = {};
                        }
                    
                        if (!groupedData[category][subCategory][module][sumTo100]) {
                        groupedData[category][subCategory][module][sumTo100] = {};
                        }
                        if (!groupedData[category][subCategory][module][sumTo100][shape]) {
                        groupedData[category][subCategory][module][sumTo100][shape] = [];
                        }
                        groupedData[category][subCategory][module][sumTo100][shape].push(data);
                    }
                }
            });
            console.log("F092524 groupedData", groupedData);
            //console.log("A083024 groupedData.Basics.Demographics[Age pyramid]", groupedData.Basics.Demographics["Age pyramid"]);
            // Start hack... Remove in the future !!!!
            // Duplicate the object from "Age pyramid" to "Stacked histograms"
            /*const pyramidForCopy = groupedData?.Basics?.Demographics ? groupedData?.Basics?.Demographics["Age pyramid"]?.Ungrouped?.Pyramid : null;
            console.log("A092524 pyramidForCopy",  pyramidForCopy);
            if (pyramidForCopy) {
                // Create a deep copy and update the Shape property for all instances
                const stackedHistograms = {
                    "Ungrouped": {
                        "Stacked histograms": pyramidForCopy.map((item: any) => ({
                            ...item, // Copy all properties of the item
                            Shape: "Stacked histograms" // Override the Shape property
                        }))
                    }
                };
                
                groupedData.Basics.Demographics["Stacked histograms"] = stackedHistograms;
            }*/
            // ...End hack
            console.log("F092524 groupedData", groupedData);
            console.log("dataActive", dataActive);
            //console.log("L092924 dataActive.meta.VARIABLE_NAME", dataActive.meta.VARIABLE_NAME);
            //console.log("L092924 dataActive.meta", dataActive.meta);
            let _excludeOptions = ["", "DISPLAY_NAME"] as any;
            let testLimit = 0; 
            let autoGenerated = {
                "Sub Category": {}
            } as any;
            Object.keys(dataActive.meta.VARIABLE_NAME).forEach((i: string) => {
                //if (testLimit < 3){
                  //console.log("L092924 dataActive.meta.VARIABLE_NAME[i]", dataActive.meta.VARIABLE_NAME[i]);
                  
                    if (!_excludeOptions.includes(dataActive.meta.DISPLAY_NAME[i])){
                        /*_indicatorOptions.push(
                            { label: dataActive.meta.DISPLAY_NAME[i], value: i, type:"swarm" }
                        )
                        _indicatorOptions.push(
                            { label: dataActive.meta.DISPLAY_NAME[i], value: i, type:"histogram" }
                        )*/
                       //console.log("L092924 i", i);
                        autoGenerated["Sub Category"][i] = {
                            "Ungrouped": {
                                "beeswarm": [
                                    {
                                        "DISPLAY_NAME": dataActive.meta.DISPLAY_NAME[i],
                                        "provenance": "auto generated",
                                        "variable": i,
                                        "Shape": "beeswarm",
                                        "Module": dataActive.meta.DISPLAY_NAME[i],
                                        "allDataPresent":true,
                                        [activeGeography.toUpperCase()]: "" // For auto generated modules, only those that exist per geo will be created, so just make up the X
                                    }
                                ], 
                                "histogram": [
                                    {
                                        "DISPLAY_NAME": dataActive.meta.DISPLAY_NAME[i],
                                        "provenance": "auto generated",
                                        "variable": i,
                                        "Shape": "histogram",
                                        "Module": dataActive.meta.DISPLAY_NAME[i],
                                        "allDataPresent":true,
                                        [activeGeography.toUpperCase()]: "" // For auto generated modules, only those that exist per geo will be created, so just make up the X
                                    }
                                ]
                            }
                        }
                        testLimit++;
                    }
                //}
                
            })
            groupedData["Auto Generated"] = autoGenerated;
            console.log("F092524 _indicatorOptions", _indicatorOptions);
            console.log("L092924 groupedData", groupedData);
            setIndicatorOptions(_indicatorOptions)
            setGroupedModules(groupedData);
        }
    }
}, [dataActive, isLoadingAllTabularData]);

  useEffect(() => {
    console.log("L092924 groupedModules",groupedModules);
  }, [groupedModules]);
  // Function to change page and preserve other parameters in order
  const changePage = (newPage: string) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('page', newPage); // Update the 'page' parameter

    // Construct the URL with parameters in the correct order
    const paramsOrder = ['page', 'lat', 'lng', 'zoom', 'selectedId', 'activeGeography'];
    const orderedParams = new URLSearchParams();
    
    paramsOrder.forEach(param => {
      const value = newSearchParams.get(param);
      if (value) orderedParams.set(param, value);
    });

    navigate(`/?${orderedParams.toString()}`, { replace: true });
  };

  return (
    <>
      <div className="top-navbar">
        <div className="menu-toggle" onClick={() => changePage('menu')}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <img src={MoALogo} alt="Measure of America" className="moa-logo" />
        <div className="navbar-title">NYC<b>DATA</b>2<b>GO</b></div>
        <div className="magnify-search-button" onClick={() => changePage('search')}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.3337" cy="11.6667" r="9.91667" fill="none" stroke="black" strokeWidth="3.5" />
            <path d="M2 28.0001L11.3333 18.6667" stroke="black" strokeWidth="3.5" strokeLinecap="round" />
          </svg>
        </div>
      </div>
      <div className="sidebar">
        <ul className="nav-links">
          <li onClick={() => changePage('explorer')}>STAT EXPLORER</li>
          <li onClick={() => changePage('dashboard')}>DASHBOARDS</li>
          <li onClick={() => changePage('compare')}>COMPARISONS</li>
        </ul>
      </div>
      <Routes>
        <Route path="/" element={currentPage === 'explorer' 
        ? <StatExplorer
            indicatorOptions={indicatorOptions}
            groupedModules={groupedModules}
            allData={allData}
        /> : currentPage === 'dashboard' ? <Dashboards /> 
        : <Comparisons
            indicatorOptions={indicatorOptions}
            groupedModules={groupedModules}
            allData={allData}
        />} />
      </Routes>
    </>
  );
}

function App() {
  const [display, setDisplay] = useState<string>("menu"); // menu, search
  const [resultsToDisplay, setResultsToDisplay] = useState<boolean>(false); // Controls search results display
  const [displayContext, setDisplayContext] = useState<string>("closed"); // closed, menuOpen, searchOpen
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false); // Control fullscreen search popup
  const [indicatorOptions, setIndicatorOptions] = useState<any | null>(null);
  const [groupedModules, setGroupedModules] = useState<any | null>(null);
  const [allData, setAllData] = useState<any | null>(null);
  const activeGeography = useGlobalActiveGeography();
  
  const toggleMenu = () => {
    // Using state variables in the return for this component will cause costly re-renders, 
    // lets do it the old-fashioned way here.
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      setDisplay(displayContext === "closed" ? "menu" : display);
      setDisplayContext(displayContext === "closed" ? "menuOpen" : "closed");
      mainContent.classList.toggle('show-menu'); // Toggle the 'show-menu' class directly
    }
  };

  const toggleSearchPopup = () => {
    console.log('Search button clicked');
    setIsSearchOpen(!isSearchOpen); // Toggle fullscreen popup
  };

  const closeSearchPopup = () => {
    setIsSearchOpen(false); // Close the popup
  };

  return (
    <DataProvider title="NYC Data2Go">
      <Router>
        {/* Ensure all components using Router hooks are within the Router context */}
        <div className="top-navbar">
          <div className="menu-toggle" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div>
            <img src={MoALogo} alt="Measure of America" className="moa-logo" />
          </div>
          <div className="navbar-title">NYC<b>DATA</b>2<b>GO</b></div>
          <div className="magnify-search-button"
            onClick={() => {
              console.log('Search button clicked'); // Logging button click for debug
              toggleSearchPopup();
            }}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18.3337" cy="11.6667" r="9.91667" fill="none" stroke="black" strokeWidth="3.5" />
              <path d="M2 28.0001L11.3333 18.6667" stroke="black" strokeWidth="3.5" strokeLinecap="round" />
            </svg>
          </div>
        </div>
        {/* Fullscreen Search Popup */}
        <SearchPopup 
          isOpen={isSearchOpen} 
          onClose={toggleSearchPopup} 
          indicatorOptions={indicatorOptions}
          groupedModules={groupedModules}
          allData={allData}
        />
        <div className={`main-content`}>
          <Navigation           
            indicatorOptions={indicatorOptions}
            groupedModules={groupedModules}
            allData={allData}
            setAllData={setAllData}
            setGroupedModules={setGroupedModules}
            setIndicatorOptions={setIndicatorOptions}

          /> {/* Navigation is within the Router */}
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
