import React from 'react';
import Select from 'react-select';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, onSetBivariateIndicator, onSetActiveIndicator } from '../../data/StatusStore';

interface IndicatorDropdownProps {
  options: { [key: string]: string };
}

const IndicatorDropdown: React.FC<IndicatorDropdownProps> = ({ options }) => {
  const activeIndicator = useGlobalActiveIndicator();
  const bivariateIndicator = useGlobalBivariateIndicator();

  const formattedOptions = Object.keys(options).map(key => ({
    value: key,
    label: options[key]
  }));

  const handleActiveIndicatorChange = (selectedOption: any) => {
    onSetActiveIndicator(selectedOption.value);
  };

  const handleBivariateIndicatorChange = (selectedOption: any) => {
    onSetBivariateIndicator(selectedOption.value);
  };

  return (
    <div>
      <Select
        options={formattedOptions}
        defaultValue={{ value: activeIndicator, label: options[activeIndicator] }}
        onChange={handleActiveIndicatorChange}
        placeholder="Select Active Indicator"
      />
      <Select
        options={formattedOptions}
        defaultValue={{ value: bivariateIndicator, label: options[bivariateIndicator] }}
        onChange={handleBivariateIndicatorChange}
        placeholder="Select Bivariate Indicator"
      />
    </div>
  );
};

export default IndicatorDropdown;
