import React, { useState, useEffect, useMemo } from 'react';
import { useGlobalSelectedId } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';

export interface ListChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass:string;
}


const List: React.FC<ListChartProps> = ({ options, data, width, height, colorClass }) => {
  
  console.log("%List 1 options", options);
  const [preppedData, setPreppedData] = useState<any>([]);
  const selectedId = useGlobalSelectedId();

  useEffect(() => {
    if (selectedId){
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const _preppedData = options.variables.map((v: any) => {
        return { name: v.label, value: data.dataJSON[selectedId][v.variable] };
      });
      setPreppedData(_preppedData);
    }
  };

  const total = preppedData.reduce((acc: number, d: any) => acc + d.value, 0);
  const gridSize = 10; // 10x10 grid
  const cellSize = Math.min(width / gridSize, height / gridSize);

  return (
    <div style={{ position: "relative", width, height }}>
      LIST
      <svg width={width} height={height} style={{ display: "inline-block" }}>
        <g transform={`translate(${10}, 10)`}>
          {preppedData.map((d: any, i: number) => (
            <g key={i} transform={`translate(0, ${i * 10})`}>
              <text x={15} y={5} fontSize={8} dominantBaseline="middle">
              {d2gRound(d.value)}% {d.name}
              </text>
            </g>
          ))}
        </g>
      </svg>
    </div>
  );
};

export default List;
