import React, { useEffect, useRef } from 'react';
import { MapContainer, useMap } from 'react-leaflet';
import { LatLngExpression, LatLngBoundsExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { ChoroplethLayer } from './Layers/ChoroplethLayer';
import MapRadioControl from '../inputs/MapRadioControl';
import MapZoomControl from '../inputs/MapZoomControl';
import MapHighlightedFeature from '../inputs/MapHighlightedFeature';
import L from 'leaflet';
import '@maplibre/maplibre-gl-leaflet';
import { useLocation, useNavigate } from 'react-router-dom';
import CreatePanes from './Layers/CreatePanes';
import { getOrderedQueryString } from '../../data/StatusStore';

// VectorTileLayer component to handle vector tile layers on the map
const VectorTileLayer = ({ url, pane, id }: { url: string; pane: string, id: string }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !url || !pane) return;

    let layer: any;

    const addLayer = async () => {
      try {
        // Add the vector tile layer
        layer = (L as any).maplibreGL({
          style: url,
          pane: pane,
          id: id,
        }).addTo(map);
      } catch (error) {
        console.error("Error adding vector tile layer:", error);
      }
    };

    addLayer();

    return () => {
      if (layer) {
        try {
          map.removeLayer(layer);
        } catch (error) {
          console.error("Error removing vector tile layer:", error);
        }
      }
    };
  }, [url, pane, map, id]);

  return null;
};

// Component to reset the map's zoom level
const MapWithZoomResetControl: React.FC<{ position: LatLngExpression }> = ({ position }) => {
  const map = useMap();

  const handleZoomReset = () => {
    map.setView(position, 10); // Reset to the initial center and zoom level
  };

  return <MapZoomControl onClickButton={handleZoomReset} />;
};

interface DataItem {
  id: string;
  value: any;
}

export interface MainMapProps {
  dataArray: DataItem[];
  keyIndexDataArray: any;
  dataJSON: any;
  dataMeta: any;
  chartId: string;
  height?: string;
  width?: string;
  mapType: string;
}

export const MainMap: React.FC<MainMapProps> = ({
  keyIndexDataArray, dataArray, dataJSON, dataMeta, chartId, height, width, mapType,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract URL parameters for lat, lng, and zoom
  const searchParams = new URLSearchParams(location.search);
  const lat = parseFloat(searchParams.get('lat') || '40.7031'); // Default latitude for Manhattan
  const lng = parseFloat(searchParams.get('lng') || '-73.9712'); // Default longitude for Manhattan
  const zoom = parseInt(searchParams.get('zoom') || '10'); // Default zoom level

  const position: LatLngExpression = [lat, lng]; // Set initial position
  const bounds: LatLngBoundsExpression = [
    [40.0358181900000005, -74.8665193700000060],
    [41.3165406899999965, -73.0809477399999992],
  ]; // Bounding box for map

  useEffect(() => {
    console.log("A090724 keyIndexDataArray", keyIndexDataArray);
    console.log("A090724 dataArray", dataArray);
    console.log("A090724 dataJSON", dataJSON);
    console.log("A090724 dataMeta", dataMeta);
  }, [keyIndexDataArray, dataArray, dataJSON, dataMeta]);

  const mapRadioOptions = [
    {
      category: "by Geography",
      options: [
        { label: "Boro", value: "Boro", enabled: false },
        { label: "CD", value: "CD", enabled: true },
        { label: "NTA", value: "NTA", enabled: false },
        { label: "Tract", value: "Tract", enabled: true },
      ],
    },
    {
      category: "by Race",
      options: [
        { label: "all", value: "all", enabled: false },
        { label: "black", value: "black", enabled: false },
        { label: "white", value: "white", enabled: false },
        { label: "asian", value: "asian", enabled: false },
        { label: "latino", value: "latino", enabled: false },
        { label: "other", value: "other", enabled: false },
      ],
    },
    {
      category: "by Gender",
      options: [
        { label: "all", value: "all", enabled: false },
        { label: "female", value: "female", enabled: false },
        { label: "male", value: "male", enabled: false },
      ],
    },
  ];

  const handleRadioChange = (value: string) => {
    console.log("Selected Radio Value:", value);
    // Implement your handling logic
  };

  const updateUrlParams = (newLat: number, newLng: number, newZoom: number) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('lat', newLat.toFixed(5));
    newSearchParams.set('lng', newLng.toFixed(5));
    newSearchParams.set('zoom', newZoom.toString());
    let updatedOrderedQueryString = getOrderedQueryString(newSearchParams);
    navigate(`?${updatedOrderedQueryString.toString()}`, { replace: true });
  };

  const MapWithHandlers: React.FC = () => {
    const map = useMap();

    useEffect(() => {
      const handleMoveEnd = () => {
        const center = map.getCenter();
        const zoom = map.getZoom();
        updateUrlParams(center.lat, center.lng, zoom);
      };

      map.on('moveend', handleMoveEnd);

      return () => {
        map.off('moveend', handleMoveEnd);
      };
    }, [map]);

    return null;
  };

  return (
    <>
      <MapContainer
        center={position}
        zoom={zoom}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{
          height: height ? height : "100px",
          width: width ? width : "100%",
        }}
        id={"mainMapContainer"}
        maxBounds={bounds}
        maxBoundsViscosity={0}
        minZoom={9} // Set your minimum zoom level
        maxZoom={25} // Set your maximum zoom level
        attributionControl={false} // Disable the attribution control
        key={chartId}
      >
        <CreatePanes />
        <MapWithHandlers />

        {/* Background vector tile layer */}
        <VectorTileLayer
          url="/data/geo/alidade_smooth_base.json" // https://maplibre.org/maputnik
          pane="vectorTilePane"
          id={"vectorTileBaseLayer"}
        />

        {/* Choropleth layer */}
        {dataArray ? (
          <ChoroplethLayer
            _keyIndexDataArray={keyIndexDataArray}
            _dataArray={dataArray}
            dataJSON={dataJSON}
            dataMeta={dataMeta}
            chartId={chartId}
            mapType={mapType}
          />
        ) : null}

        {/* Labels vector tile layer */}
        <VectorTileLayer
          url="/data/geo/alidade_smooth_labels.json" // https://maplibre.org/maputnik
          pane="vectorTileLabelPane"
          id="vectorTileLabelLayer"
        />

        {/* Map radio control */}
        <MapRadioControl
          categoryOptions={mapRadioOptions}
          defaultValue="1"
          onChange={handleRadioChange}
        />

        {/* Map zoom reset control */}
        <MapWithZoomResetControl position={position} />
        <MapHighlightedFeature dataJSON={dataJSON} />
      </MapContainer>
    </>
  );
};

export default MainMap;
