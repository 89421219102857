import React, { useState } from 'react';
import styled from 'styled-components';
import { ExportToImage } from '../utilities/exports/image/ExportToImage';
import { ExportToExcel } from '../utilities/exports/xlsx/ExportToExcel';
import { onHoverHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, useGlobalActiveIndicator, useGlobalBivariateIndicator } from '../../data/StatusStore';

interface ExportModalProps {
  title: string;
  isVisible: boolean;
  onClose: () => void;
  dataGeo: any;
  activeIndicator: string;
  bivariateIndicator: string | null;
}

// Use shouldForwardProp to prevent isVisible from being passed to the DOM
const ModalBackdrop = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* High z-index */
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(0%, -50%);
  background-color: #f7d6d0; /* Pink background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  width: 550px;
  height: 400px;
  z-index: 10000; /* Ensure it is above the backdrop */
  border: 1px solid #231f20;
`;

const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ToggleItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
`;

const ToggleSwitch = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>`
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.isActive ? '#231f20' : '#8d8d8d')};
  border-radius: 20px;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    top: 1px;
    left: ${(props) => (props.isActive ? '20px' : '1px')};
    transition: left 0.3s ease;
  }
`;

const StyledRadio = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 10px;

  &:checked::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  gap: 10px;
`;

const ActionButton = styled.div`
  padding: 10px 15px;
  border: none;
  border-radius: 0px;
  background-color: none;
  border: 1px solid #231f20;
  color: #231f20;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  width: 120px;
  min-height: 64px;
  &:hover {
    color: #a29f9f;
    border: 1px solid #a29f9f;
  }
`;

const EmailInput = styled.input`
  padding: 8px;
  border: 1px solid #231f20;
  border-radius: 0px;
  font-size: 14px;
  width: 100%;
  background-color: #ffffff00;

  /* Remove default focus outline and set custom border */
  &:focus {
    outline: none;
    border-color: #231f20;
  }
`;

const ExportModal: React.FC<ExportModalProps> = ({ title, isVisible, onClose, dataGeo, activeIndicator, bivariateIndicator }) => {

  console.log("082524 dataGeo", dataGeo);
  console.log("082524 activeIndicator", activeIndicator);
  console.log("082524 bivariateIndicator", bivariateIndicator);

  const activeGeography = useGlobalActiveGeography();

  const [includeMap, setIncludeMap] = useState<boolean>(true);
  const [includeChart, setIncludeChart] = useState<boolean>(false);
  const [includeHighlightedArea, setIncludeHighlightedArea] = useState<boolean>(false);

  const handleExport = () => {
    console.log('Exporting image...');
    const ids = ['mapHistContainer'];
    ExportToImage(ids, 'exported-image.png', 'exportModalBackdrop');
  };
  const handleExportXLSX = () => {
    // Call the function to export JSON to Excel
    ExportToExcel({ dataGeo: dataGeo, activeIndicator: activeIndicator, bivariateIndicator: bivariateIndicator, activeGeography:activeGeography });
  };

  return (
    <ModalBackdrop isVisible={isVisible} onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalContent>
          <ToggleItem>
            <ToggleSwitch isActive={includeMap} onClick={() => setIncludeMap(!includeMap)} />
            Include map?
          </ToggleItem>
          <ToggleItem>
            <ToggleSwitch isActive={includeChart} onClick={() => setIncludeChart(!includeChart)} />
            Include bar chart?
          </ToggleItem>
          <ToggleItem>
            <ToggleSwitch isActive={includeHighlightedArea} onClick={() => setIncludeHighlightedArea(!includeHighlightedArea)} />
            Include highlighted area?
          </ToggleItem>
          <ToggleItem>
            <StyledRadio name="annotation" id="short" />
            <label htmlFor="short">Short annotation</label>
          </ToggleItem>
          <ToggleItem>
            <StyledRadio name="annotation" id="full" defaultChecked />
            <label htmlFor="full">Full annotation</label>
          </ToggleItem>
        </ModalContent>
        <ModalFooter>
          <ActionButton>Post to X</ActionButton>
          <ActionButton onClick={handleExport}>Export as .jpg</ActionButton>
          <ActionButton onClick={handleExportXLSX}>Export as .xls</ActionButton>
          <div>
            <div>Email to:</div>
            <EmailInput type="email" placeholder="enter email" />
          </div>
        </ModalFooter>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default ExportModal;
