import * as XLSX from 'xlsx';

interface ExportToExcelProps {
  dataGeo: any;  // Object where each key is an identifier and the value is an object with properties
  activeIndicator: string;
  bivariateIndicator: string | null;
  activeGeography: string | null;
}

export const ExportToExcel = ({ dataGeo, activeIndicator, bivariateIndicator, activeGeography }: ExportToExcelProps): void => {
  // Reshape the data to an array of objects
  const activeData = dataGeo.keyIndexDataArray[activeIndicator];
  const bivariateData = bivariateIndicator ? dataGeo.keyIndexDataArray[bivariateIndicator] : null;

  const reshapedData = Object.keys(activeData).map((key) => ({
    GEO_ID: key,  // Add the key as an 'id' column
    [activeIndicator]: activeData[key].value,
    [`${activeIndicator}_rank`]: activeData[key].index,
    ...(bivariateIndicator !== null && bivariateIndicator !== undefined ? {
      [bivariateIndicator]: bivariateData[key].value,
      [`${bivariateIndicator}_rank`]: bivariateData[key].index
    } : {})
  }));
  console.log("A082524 ExportToExcel reshapedData", reshapedData);
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert reshaped data to worksheet
  const worksheet = XLSX.utils.json_to_sheet(reshapedData);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, `by ${activeGeography}`);

  // Generate metadata worksheet
  const metaData = dataGeo.meta;
  const metadataArray = Object.entries(metaData)
  .filter(([key]) => !key.includes("Row_"))  // Filter out keys that contain "Row_"
  .map(([key]) => {
    return bivariateIndicator 
      ? { "Metadata": key, [activeIndicator]: metaData[key][activeIndicator], [bivariateIndicator]: metaData[key][bivariateIndicator] }
      : { "Metadata": key, [activeIndicator]: metaData[key][activeIndicator] };
  });

  console.log("A082524 ExportToExcel metadataArray", metadataArray);
  const metadataSheet = XLSX.utils.json_to_sheet(metadataArray);

  // Append the metadata worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, metadataSheet, 'Metadata');

  // Generate the Excel file and trigger a download
  let _fileName = `${activeGeography}_${activeIndicator}${bivariateIndicator ? `--${bivariateIndicator}` : ''}`;
  XLSX.writeFile(workbook, `${_fileName}.xlsx`);
};
