// DashboardView.tsx
import React, { useState, useEffect } from 'react';
import { useData, FlatFileCsvData} from '../data/DataContext'; // Adjust the import path as needed
import './views.scss';

const DashboardView: React.FC = () => {
  const { 
    dataTract, dataPUMA, dataNYC, dataCD, dataBoro, dataSorter,
    isLoadingTract, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, isLoadingSorter,
    error } = useData();
  //const [DashboardData, setDashboardData] = useState<FlatFileCsvData | null>(null);
  useEffect(() => {
    if (!isLoadingTract && dataTract) {
       //setDashboardData(dataTract);
       console.log("dataTract", dataTract);
    }
  }, [dataTract, isLoadingTract]);

  useEffect(() => {
    if (!isLoadingPUMA && dataPUMA) {
       //setDashboardData(dataPUMA);
       console.log("dataPUMA", dataPUMA);
    }
  }, [dataPUMA, isLoadingPUMA]);

  useEffect(() => {
    if (!isLoadingNYC && dataNYC) {
       console.log("dataNYC", dataNYC);
    }
  }, [dataNYC, isLoadingNYC]);

  useEffect(() => {
    if (!isLoadingCD && dataCD) {
       console.log("dataCD", dataCD);
    }
  }, [dataCD, isLoadingCD]);

  useEffect(() => {
    if (!isLoadingBoro && dataBoro) {
       console.log("dataBoro", dataBoro);
    }
  }, [dataBoro, isLoadingBoro]);

  useEffect(() => {
    if (!isLoadingSorter && dataSorter) {
       console.log("dataSorter", dataSorter);
    }
  }, [dataSorter, isLoadingSorter]);

  if (isLoadingTract || isLoadingPUMA || isLoadingNYC || isLoadingCD || isLoadingBoro || isLoadingSorter) return <p>Loading dashboard data...</p>;
  if (error) return <p>Error loading dashboard data: {error}</p>;

  return (
    <div>
      <h1>Dashboard View</h1>
      {/* Render your dashboard here using the DashboardData state */}
    </div>
  );
};

export default DashboardView;