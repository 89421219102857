import domtoimage from 'dom-to-image';

export const ExportToImage = async (
  elementIds: string[],
  fileName: string = 'exported-image.png',
  excludeElementId: string | null = null
) => {
  try {
    const imageArray: HTMLImageElement[] = [];

    // Temporarily hide the excluded element if an ID is provided
    let excludedElement: HTMLElement | null = null;
    if (excludeElementId) {
      excludedElement = document.getElementById(excludeElementId);
      if (excludedElement) {
        excludedElement.style.visibility = 'hidden';
      }
    }

    // Capture all elements and vector tiles
    for (const id of elementIds) {
      //const element = document.getElementsByClassName("leaflet-container")[0] as HTMLElement;
      const element = document.getElementById(id);
      if (element) {
        const dataUrl = await domtoimage.toPng(element, {
          quality: 2,
          bgcolor: 'white', // Ensure background is white
          //style: {
          //  transform: 'none', // Disable CSS transforms
          //},
        });
        const img = new Image();
        img.src = dataUrl;
        await new Promise((resolve) => (img.onload = resolve));
        imageArray.push(img);
      } else {
        console.warn(`Element with ID ${id} not found.`);
      }
    }

    // Restore the excluded element's visibility
    if (excludedElement) {
      excludedElement.style.visibility = 'visible';
    }

    if (imageArray.length > 0) {
      const combinedCanvas = document.createElement('canvas');
      const ctx = combinedCanvas.getContext('2d')!;
      const totalWidth = Math.max(...imageArray.map((img) => img.width));
      const totalHeight = imageArray.reduce((sum, img) => sum + img.height, 0);

      combinedCanvas.width = totalWidth;
      combinedCanvas.height = totalHeight;

      let offsetY = 0;
      for (const img of imageArray) {
        ctx.drawImage(img, 0, offsetY);
        offsetY += img.height;
      }

      const link = document.createElement('a');
      link.download = fileName;
      link.href = combinedCanvas.toDataURL('image/png');
      link.click();
    }
  } catch (error) {
    console.error('Failed to export image:', error);
  }
};
