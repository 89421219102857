import React, { useEffect, useState } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import { onSetActiveGeography, useGlobalActiveGeography, onSetCachedActiveIndicators,  onSetCachedSelectedIds, useGlobalCachedActiveIndicators, useGlobalCachedSelectedIds, onSetActiveIndicator, onSetBivariateIndicator } from '../../data/StatusStore';
import Colors from '../utilities/Colors';
import { useGlobalHoveredId, useGlobalSelectedId } from '../../data/StatusStore';

interface MapZoomControlProps {
  //onClickButton: () => void;
  dataJSON: any;
}

const MapZoomControl: React.FC<MapZoomControlProps> = ({dataJSON }) => {
  const map = useMap();
  const activeGeography = useGlobalActiveGeography();

  //const [selectedValue, setSelectedValue] = useState<string>(activeGeography);

  const hoveredId = useGlobalHoveredId();
  const selectedId = useGlobalSelectedId();

  const lookupDescriptiveName = (activeGeography: string, hoveredId: string | null, selectedId: string | null) => {
      return hoveredId ? dataJSON[hoveredId]?.GEO_LABEL : selectedId ? dataJSON[selectedId]?.GEO_LABEL : "";
  }
  useEffect(() => {
    // Create a Leaflet Control to position the radio buttons on the map
    const controlContainer = L.DomUtil.create('div', 'leaflet-control leaflet-bar');
    const customControl = new L.Control({ position: 'topright' }); // You can adjust the position

    customControl.onAdd = function() {
      L.DomEvent.disableClickPropagation(controlContainer);
      return controlContainer;
    };

    customControl.addTo(map);

    return () => {
      customControl.remove();
    };
  }, [map]);

  useMapEvent('click', () => {
    // Optional: Respond to map clicks or other events
  });
  //console.log("G071024 hoveredId", hoveredId);
  return (
    <div className="leaflet-control-container">
      <div className="leaflet-bottom leaflet-right">
        <div className="leaflet-control leaflet-control-custom"
          style={{
            paddingBottom: "3px",
            paddingTop: "3px",
            paddingRight: "10px",
            paddingLeft: "10px",
            //cursor: "pointer",
            minHeight: "20px",
            minWidth: "140px",
            color: hoveredId ? Colors.highlightHover : Colors.highlightSelect,
            border: `1px solid ${hoveredId ? Colors.highlightHover : Colors.highlightSelect}`,
          }}
          //onClick={onClickButton}
        >
           {`${activeGeography} ${hoveredId ? hoveredId : selectedId}`}<br/>
           {`${lookupDescriptiveName(activeGeography, hoveredId, selectedId)}`}
        </div>
      </div>
    </div>
  );
};

export default MapZoomControl;
