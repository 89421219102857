import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import Colors from '../utilities/Colors';
import { d2gRound } from '../utilities/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { onHoverHistogram, onClickHistogram, onSetActiveIndicator } from '../../data/StatusStore';
import { useGlobalSwarmCalibration } from '../../data/GlobalStore';
import { relative } from 'path';

interface BaseDataItem {
  id: string;
  value: number;
}

interface DodgeDataItem extends BaseDataItem {
  x: number;
  y: number;
  next: DodgeDataItem | null;
}

interface DataItem {
  id: string;
  value: number;
  x?: number;
  y?: number;
}

interface SwarmStaticProps {
  dataArray: BaseDataItem[];
  histMax: number;
  histMin: number;
  histNA: number;
  histLength: number;
  chartId: string;
  nodes: DataItem[];
  setNodes: Function;
  indicator: string;
  colorClass: string;
}

const SwarmStatic: React.FC<SwarmStaticProps> = ({
  dataArray,
  chartId,
  histMax,
  histMin,
  histNA,
  histLength,
  nodes,
  setNodes,
  indicator,
  colorClass
}) => {

const swarmCalibration = useGlobalSwarmCalibration();

const width = "100%";
const height = "100%";

const [axisLabels, setAxisLabels] = useState<any>({});
const [isLoading, setIsLoading] = useState<boolean>(true);
const [collisionDiameter, setCollisionDiameter] = useState<number>(swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].collisionDiameter);
const [pointDiameter, setPointDiameter] = useState<number>(swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].radius * 2);
//const [pointDiameter, setPointDiameter] = useState<number>(swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].pointDiameter);
const [padding, setPadding] = useState<number>(swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].padding);
const [radius, setRadius] = useState<number>(swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].radius);

function calculateSwarmPositions(data: DataItem[], min: number, max: number, plotWidth: number, pointDiameter: number, collisionDiameter:number, stackCount:number, _testEachPoint:boolean): DataItem[] {
  const radius = pointDiameter / 2;
  const collisionRadius = collisionDiameter; // / 2;
  let lastPoint: DataItem | null = null;
  let direction = 1;  // Start by placing points above the axis (1 for above, -1 for below)
  let run = 0;
  //console.log("G092924 data", data)
  //console.log("G092924 nodes", nodes)
  data.forEach((item, index) => {
      // Calculate the x position as a percentage of the value between min and max
      item.x = (((item.value - min) / (max - min)) * (plotWidth - pointDiameter )) + (pointDiameter / 2);

      // Initialize y position
      item.y = 0;
   
      if (lastPoint && Math.abs(lastPoint.x! - item.x!) < collisionRadius * 2) {
          // If overlapping, adjust y position. Alternate placing points above and below the line.
          item.y = (run * (radius * 2)) * direction;
          //run++;
          if (direction === 1) {
            run = run + 1;
          }
          // Toggle direction for the next potential overlap
          direction *= -1;
      } else {
          // Reset direction to 1 (above) whenever there is no overlap
          direction = 1;
          run = 0;
          if (!_testEachPoint) {
            lastPoint = item;
          }
      }
      if (Math.abs(item.y) > stackCount) {
        run=0;
        lastPoint = item;
      }

      // Update the lastPoint reference for the next iteration
      if (_testEachPoint){
        lastPoint = item;
      }
  });

  return data;
}

interface AxisLabel {
  tickValue: number;
  width: string;
}

function computeAxisLabels(min: number, max: number, minTicks: number = 3, maxTicks: number = 8): AxisLabel[] {
  let range = max - min;
  let idealStep = range / (minTicks + (maxTicks - minTicks) / 2);
  let stepSize = getNiceNumber(idealStep, false);
  let tickCount = Math.ceil(range / stepSize);

  // Adjust stepSize if tickCount is not within the desired range
  while (tickCount < minTicks) {
    stepSize /= 2;
    tickCount = Math.ceil(range / stepSize);
  }
  while (tickCount > maxTicks) {
    stepSize *= 2;
    tickCount = Math.ceil(range / stepSize);
  }

  let ticks:any = [];
  for (let i = 0; i <= tickCount; i++) {
    ticks.push(min + i * stepSize);
  }
  if (ticks[ticks.length - 1] !== max) ticks[ticks.length - 1] = max; // Ensure the last tick is exactly max

  // Calculate widths
  const totalTicks = ticks.length;
  const labels: AxisLabel[] = ticks.map((tick:any, index:number) => {
    if (index < totalTicks - 1) {
      let segmentWidth = ((ticks[index + 1] - tick) / range * 100).toFixed(2) + "%";
      return { tickValue: tick, width: segmentWidth };
    }
    return { tickValue: tick, width: "0%" };
  });

  // Remove placeholder and adjust last segment width if necessary
  labels.pop();
  let lastWidth = 100 - labels.reduce((acc, curr) => acc + parseFloat(curr.width), 0);
  labels.push({ tickValue: max, width: lastWidth.toFixed(2) + "%" });

  return labels;
}

function getNiceNumber(range: number, round: boolean): number {
  const exponent = Math.floor(Math.log10(range));
  const fraction = range / Math.pow(10, exponent);
  let niceFraction: number;

  if (fraction <= 1) niceFraction = 1;
  else if (fraction <= 2) niceFraction = 2;
  else if (fraction <= 2.5) niceFraction = 2.5;
  else if (fraction <= 5) niceFraction = 5;
  else if (fraction <= 7.5) niceFraction = 7.5;
  else niceFraction = 10;

  return niceFraction * Math.pow(10, exponent);
}



/*const radius = dataArray 
  ? dataArray.length > 100 
    ? 1 
    : 2
  :1; // Radius of the circles on the plot
const padding = dataArray
  ? dataArray.length > 100 
    ? .25 
    : 0.5
  :.25; // Padding between circles on the plot*/
  
const plotWidth = 100; // Total width of the plot in percent

/*const pointDiameter = dataArray
  ? dataArray.length > 100 
    ? .75 
    : 2
  : .75; // The smaller this number, the less space between points
const collisionDiameter = dataArray
  ? dataArray.length > 100 
    ? swarmCalibration.Over100.collisionDiameter
    : 1
  : .15; // The smaller this number, the more sensitive the collision detection*/

useEffect(() => {
  //console.log("G092924 chartId", chartId)
  let _collisionDiameter = swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].collisionDiameter;
  let _radius = swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].radius;
  let _pointDiameter = _radius * 2;
  //let _pointDiameter = swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].pointDiameter;
  let _padding = swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].padding;
  let _stackCount = swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].stackCount;
  let _testEachPoint = swarmCalibration[dataArray.length > 100 ? "Over100" : "Under100"].testEachPoint === "TRUE" ? true : false;
  setCollisionDiameter(_collisionDiameter);
  setPointDiameter(_pointDiameter);
  setRadius(_radius);
  setPadding(_padding);
  /*console.log("C051024 swarmCalibration", swarmCalibration)
  console.log("C051024 _collisionDiameter", _collisionDiameter)
  console.log("C051024 _pointDiameter", _pointDiameter)
  console.log("C051024 _radius", _radius)
  console.log("C051024 _padding", _padding)
  console.log("C051024 indicator", indicator)
  console.log("D051024 histMax", histMax)
  console.log("D051024 histMin", histMin)*/
  const swarmData = calculateSwarmPositions(dataArray, histMin, histMax, plotWidth, _pointDiameter, _collisionDiameter, _stackCount, _testEachPoint);
  setNodes(swarmData);
  const _axisLabels = computeAxisLabels(histMin, histMax, /*parseInt(width),*/ 5); 
  //console.log("E051024 histMin, histMax, parseInt(width)", histMin, histMax, parseInt(width))
  //console.log("E051024 _axisLabels", _axisLabels)
  setAxisLabels(_axisLabels);
  setIsLoading(false);

}, [dataArray, height, width, histMax, histMin, histLength, /*collisionDiameter, pointDiameter,*/ swarmCalibration]);

useEffect(() => {
  //console.log("C051024 collisionDiameter", collisionDiameter)
  //console.log("C051024 pointDiameter", pointDiameter)
}, [collisionDiameter, pointDiameter]);


return (      
  <div className='swarm-container'>
    { isLoading 
  ? (
    <div className="loading-spinner">
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  ) : (
    <svg width={width} height={height} className="swarm-chart-static">
      {nodes.map((node, i) => {
        const color = Colors.getColorQuintile(i, histNA, histLength, colorClass);
        return (
          <circle
            key={`${node.id}_${chartId}_${i}`}
            cx={node.x 
              ? `${node.x}%` 
              : 0}
            //cx={node.value ? `${(((node.value - histMin) / (histMax - histMin)) * 100)}%` : 0}
            cy={node.y 
              ? `${((parseInt(height) / 2 - radius - padding - node.y) / parseInt(height)) * 100}%`
              : `${((parseInt(height) / 2 - radius - padding) / parseInt(height)) * 100}%`} // Adjust y position based on radius and padding
            //cy={parseInt(height) / 2 - radius - padding - node.y} // Adjust y position based on radius and padding
            //cy={`${node.y / 2}%)`} // Adjust y position based on radius and padding
            //cy={`calc(${parseInt(height) / 2 } - ${node.y}%)`} // Adjust y position based on radius and padding
            r={radius}
            fill={color}
            onMouseOver={() => onHoverHistogram(node.id)}
            onClick={() => {
              onClickHistogram(node.id)
              onSetActiveIndicator(chartId)
            }}
            className="swarm-circle"
          />
        );
      })}
    </svg>)}
    <div
      style={{
        width: "calc(100% - 20px)",
        height:"5px",
        //backgroundColor: "lightgray",
        marginLeft:"10px",
        border: "1px solid black",
        borderBottom: "0px solid black", 
        borderRight: "0px solid black", 
        display: "flex",
      }}
    >
      { axisLabels && axisLabels.length
      ? axisLabels.map((label:any, i:number) => (
        <div
          key={i}
          style={{
            width: label.width,
            //position:"relative",
            textAlign: "center",
            //left: `${parseInt(label.width) / -2}%`,
            height: "100%",
            borderRight:"1px solid black",
            //borderRight: i + 2 < axisLabels.length ? "1px solid black" : "0px solid black",
            fontSize: "10px",
          }}
        >
        <div
          key={i}
          style={{
            width: "100%",
            //width: i + 1 < axisLabels.length ? "100%" : "100px",
            position:"relative",
            textAlign: "center",
            //textAlign: i + 1 < axisLabels.length ? "center" : "right",
            left:"-50%",
            //left: i + 1 < axisLabels.length ? "-50%" : "-100px",
            height: "100%",
            fontSize: "10px",
          }}
        >
          {d2gRound(label.tickValue)}
        </div>
        </div>
      ))
    : null}
    </div>
  </div>)};

export default SwarmStatic;
