import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';

export interface DeviationFromAverageChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass:string;
}

const MARGIN_X = 15;
const MARGIN_Y = 15;

const DeviationFromAverage: React.FC<DeviationFromAverageChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [barHeight, setBarHeight] = useState<number>(0);
  const selectedId = useGlobalSelectedId();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });
  const [maxDeviation, setMaxDeviation] = useState<number>(0);
  const [minDeviation, setMinDeviation] = useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => !v.sort.Tooltip_only);
      const _preppedData = _filteredData.map((v: any) => ({
        name: v.label,
        deviation: data.meta.avg[v.variable] - data.dataJSON[selectedId][v.variable],
        value: data.dataJSON[selectedId][v.variable],
        average: data.meta.avg[v.variable],
        variable: v.variable,
        //colorClass:
      }));
      setPreppedData(_preppedData);
    }
  };

  useEffect(() => {
    setMaxDeviation(Math.max(...preppedData.map((d: any) => Math.abs(d.deviation))));
    setMinDeviation(Math.min(...preppedData.map((d: any) => Math.abs(d.deviation))));
    setBarHeight(40);
  }, [preppedData]);

  const barScale = (value: number) => (value / maxDeviation) * ((dimensions.width / 2 - 50) / 2);

  const onClicked = (variable: string) => {
    onSetActiveIndicator(variable);
  };

  const fontSize = 12; // For dynamic heights --> // Math.min(12, Math.max(8, dimensions.width / 50));
  const fontSize2 = 12; // For dynamic heights --> // Math.min(10, Math.max(8, dimensions.width / 50));
  const chartSize = Math.min(dimensions.width / 2, dimensions.height / 2);
  const labelWidth = "250px"; //`calc(40% - ${((chartSize + 10))}px)`;
  const chartWidth = `calc(100% - 250px)`; // `calc(60% + ${((chartSize + 10))}px)`;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight:"16px" }}>
      <div 
        style={{ 
          marginLeft: '0px', 
          marginRight: '0px', 
          fontSize:fontSize2, 
          lineHeight: `${fontSize2 + 2 }px`,//'1', 
          width: labelWidth 
          }}
        >
        {preppedData.map((d: any, i: number) => (
          <div key={`info-${i}`} 
            style={{ 
              fontSize:fontSize2, 
              lineHeight: `${fontSize2 + 2 }px`,//'1'
              display: "inline-flex", 
              justifyContent: "space-between", 
              height:"40px", 
            }}
          >
              <div style={{ fontWeight: 'bold', width:"45px", alignContent: "center" }}>{d2gRound(d.value, {returnString: true})}</div>
              <div style={{ fontWeight: 'normal', width: "calc(100% - 45px)", alignContent: "center", paddingRight:"15px" }}>{d.name}</div>
          </div>
        ))}
      </div>
      <div 
        style={{ 
          fontSize, 
          lineHeight: `${fontSize + 2 }px`, //'1', 
          display: 'flex', /*flexDirection: 'column',*/ 
          width: chartWidth, 
          position:"relative" 
        }}
      >
        <svg width={"100%"} height={barHeight * preppedData.length} style={{ display: "inline-block" }}>
          <g transform={`translate(${barScale(Math.abs(maxDeviation))}, 0)`}>
            {preppedData.map((d: any, i: number) => (
              <g key={`deviation-${i}`} transform={`translate(0, ${i * barHeight})`}>
                <rect
                  x={d.value >= d.average ? 0 : -barScale(d.deviation)}
                  y={0}
                  width={barScale(Math.abs(d.deviation))}
                  height={barHeight - 2}
                  fill={Colors.getColorQuintile(Math.abs(d.deviation), minDeviation, maxDeviation, colorClass)}
                  onClick={() => onClicked(d.variable)}
                  style={{ cursor: 'pointer' }}
                />
              </g>
            ))}
            <line
              x1={0}
              y1={0}
              x2={0}
              y2={barHeight * preppedData.length}
              stroke="black"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default DeviationFromAverage;
