import React, { useState, useEffect, useRef } from 'react';
import Colors from '../utilities/Colors';
import { useGlobalHoveredId, useGlobalSelectedId } from '../../data/StatusStore';
interface DataItem {
  id: string;
  value: any;
}

export interface HistogramDynamicProps {
  dataArray: DataItem[];
  histMax: number;
  histNA: number;
  histLength: number;
  chartId: string;
  height: string;
  width: string;
  paddingLeft: string;
  paddingRight: string;
  tooltips: boolean;
}

export const HistogramDynamic: React.FC<HistogramDynamicProps> = ({
  dataArray, histMax, histNA, histLength, chartId, height, width, paddingRight, paddingLeft, tooltips
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const hoveredId = useGlobalHoveredId();
  const selectedId = useGlobalSelectedId();

  useEffect(() => {
    const updateContainerWidth = () => {
      if (svgRef.current) {
        setContainerWidth(svgRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener('resize', updateContainerWidth);
    updateContainerWidth(); // Initial update

    return () => window.removeEventListener('resize', updateContainerWidth);
  }, [hoveredId]);

  const viewBoxWidth = histLength * 10; // Assume each bar occupies "10" units in viewBox coordinates for consistency

  // Define the bar width in viewBox units for consistency
  const barWidthViewBoxUnits = dataArray
  ? dataArray.length > 100 
    ? 27 
    : 9
  : 27; // Adjust based on number of bars

  const barWidthAdjustment = dataArray
  ? dataArray.length > 100 
    ? 13 
    : 0
  : 13; // Adjust based on number of bars
  const _height = parseFloat(height);
  return (
    //console.log("A041824 dataArray", dataArray),
    dataArray && dataArray.length > 0 
    ? <>
    {dataArray.map((d, i) => {
        //console.log("D093024 d", d)
        const isSelected = d.id == selectedId;
        const isHovered = d.id == hoveredId;
        const fill = isSelected ? Colors.highlightSelect : isHovered ? Colors.highlightHover : 'none'; // 'none' for transparent or any default non-highlight color
        
        if (!isSelected && !isHovered) return null; // Skip rendering if not selected or hovered

        const barHeight = d.value === "NA" ? 0 : (d.value / histMax) * _height; // Adjusted for viewBox height of 80 units
        console.log("H093024 dataArray", dataArray);
        return(
          <div
            key={`${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_box_hist_cont`}
            /*style={{
              paddingRight: paddingRight,
              paddingLeft: paddingLeft,
            }}*/
          >
            <div
              className={`hist-label-rect ${isSelected ? 'popup-selected' : 'popup-hovered'}`}
              key={`${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_box_hist`}
              style={{
                width:"100px",
                height:"30px",
                backgroundColor: "#ffffff",
                border: `1px solid ${fill}`,
                position: "absolute",
                fontSize: "8px",
                textAlign: "center",
                left: `${(i * (containerWidth / dataArray.length)) - 50}px`,
                display: `${tooltips ? 'block' : 'none'}`,
              }}
            >{d.id}<br/>{d.value}</div>
            <svg 
              key={`${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_svg_hist`}
              ref={svgRef} width={width} height={`${height}px`} viewBox={`0 0 ${viewBoxWidth} ${height}`} preserveAspectRatio="none" className='hist-container-dynamic'          
              style={{
                paddingRight: paddingRight,
                paddingLeft: paddingLeft,
              }}>
              <rect
                key={`${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_rect`}
                x={(i * 10) - barWidthAdjustment} // Each bar starts at 10 units apart
                y={_height - barHeight} // Adjust based on barHeight for bottom-up growth
                width={barWidthViewBoxUnits} // Consistent bar width
                height={barHeight}
                fill={fill}
                className="hist-bar"
              />
            </svg>
          </div>
      )})}
      </>
    : null
  );
};
