import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // Strict mode will cause the app to render twice in development. 
  // React.StrictMode is a development tool that highlights potential issues in an application.
  // It activates additional checks and warnings for its descendants, such as:
  //
  // - Identifying components with unsafe lifecycles
  // - Warning about legacy string ref API usage
  // - Warning about deprecated findDOMNode usage
  // - Detecting unexpected side effects in the render phase
  // - Detecting legacy context API
  //
  // Note: Strict Mode runs only in development mode; it does not impact the production build.
  // It helps to ensure that components are ready for asynchronous rendering and helps
  // prevent issues by running checks and warning about them.
  <React.StrictMode> 
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
