import React, { useState, useEffect, FC, useMemo } from 'react';
import { Histogram } from '../../components/charts/Histogram';
import Swarm from '../../components/charts/Swarm';
import Pie from '../../components/charts/Pie';
import Waffle from '../../components/charts/Waffle';
import Time from '../../components/charts/Time';
import List from '../../components/charts/List';
import Pyramid from '../../components/charts/Pyramid';
import StackedHistograms from '../../components/charts/StackedHistograms';
import GlobeMap from '../../components/charts/GlobeMap';
import SwarmMeta from '../../components/charts/SwarmMeta';
import DeviationFromAverage from '../../components/charts/DeviationFromAverage';
import SvgIcon from '../../assets/SvgIcon';
import { Button } from 'react-bootstrap';
import { useGlobalActiveGeography, useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalSelectedId } from '../../data/StatusStore'; // Adjust the import path as necessary
import { onSetActiveIndicator, onSetBivariateIndicator, onSetSelectedId } from '../../data/StatusStore';
import { useGlobalSwarmCalibration } from '../../data/GlobalStore';

interface DataItem {
  id: string;
  value: any;
}

export interface ChartCardProps {
  chartIndicator: any;
  chartIndicatorName: string;
  allData: any |null;
  chartId: string;
  height: string;
  width: string;
  index: number;
  options: any;
  activeGeography: string;
  //onSetActiveIndicator: (indicator: string) => void;
  //onSetBivariateIndicator: (indicator: string) => void;
  //activeIndicator : string;
  //bivariateIndicator : string;
  _selectedId: string | null;
  //persistableSelectedId: string | null;
}

export const ChartCard: React.FC<ChartCardProps> = ({
  chartIndicator, chartIndicatorName, allData, chartId, height, width, _selectedId, /*persistableSelectedId, onSetActiveIndicator, onSetBivariateIndicator, activeIndicator, bivariateIndicator*/ options, activeGeography, index
}) => {

  //console.log("N092924 ---", options)
  const activeIndicator = useGlobalActiveIndicator();
  const bivariateIndicator = useGlobalBivariateIndicator();
  const selectedId = useGlobalSelectedId();
  const [persistableSelectedId, setPersistableSelectedId] = useState<string | null>(selectedId);
  const [key, setKey] = useState(0);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width:700, height:180 });
  //const activeGeography = useGlobalActiveGeography();

  const swarmCalibration = useGlobalSwarmCalibration();

  const handlePinVar1 = (e: any, indicator:string) => {
    console.log('Pin 1',e, indicator);
    onSetActiveIndicator(indicator);
  };
  const handlePinVar2 = (e: any, indicator:string) => {
    console.log('Pin 2',e, indicator);
    onSetBivariateIndicator(indicator);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  const gridSize = 10; // 10x10 grid
  const chartSize = Math.min(dimensions.width / 2, dimensions.height / 2); // Reduce the chart size
  const [preppedData, setPreppedData] = useState<any>([]);;
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  
  const fontSize = 12; // For dynamic heights --> // Math.min(12, Math.max(8, dimensions.width / 50));
  const fontSize2 = 12; // For dynamic heights --> // Math.min(10, Math.max(8, dimensions.width / 50));
  const valueWidth = 120; // Width allocated for the value text
  const labelWidth = dimensions.width - chartSize - valueWidth - 30;

  useEffect(() => {
    //console.log("D060524 ------------> IntersectionObserver selectedId",selectedId);
    //setKey(prevKey => prevKey + 1); // Force re-render
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            //console.log("D060524 ------------> IntersectionObserver entry",entry);
            //console.log("D060524 ------------> IntersectionObserver selectedId",selectedId);
            //console.log("D060524 ------------> IntersectionObserver _selectedId",_selectedId);
            //console.log("D060524 ------------> IntersectionObserver persistableSelectedId",persistableSelectedId);
            if (selectedId){
              onSetSelectedId(selectedId); 
              setPersistableSelectedId(selectedId); 
            }else if (_selectedId){
              onSetSelectedId(_selectedId); 
              setPersistableSelectedId(_selectedId); 
            }else if (persistableSelectedId){
              onSetSelectedId(persistableSelectedId); 
            }
          }
        });
      },
      {
        rootMargin: '400px 0px', // Increase the root margin to load charts earlier
        threshold: 0.1, // Lower the threshold to trigger earlier
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [chartIndicator, selectedId]);

  const memoizedHistogram = useMemo(() => (
    //console.log("J092924 allData", allData),
    //console.log("J092924 allData[activeGeography]", allData[activeGeography]),
    //console.log("M092924 chartIndicator", chartIndicator),
    //console.log("J092924 options", options),
    chartIndicator && chartIndicator.length > 0 
      ? chartIndicator.map((subIndicator: any, index: number) => (
        //console.log("M092924 subIndicator", subIndicator),
        //console.log("M092924 allData[activeGeography]", allData[activeGeography]),
        //console.log("M092924 subIndicator?.variable", subIndicator?.variable),
        subIndicator && allData[activeGeography]?.dataArray[subIndicator?.variable]
          ? <div 
              ref={containerRef} 
              style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight:"16px" }}
              key={`chart-card-hist-${index}`}
              >
              <div 
                style={{ 
                  marginLeft: '0px',
                  marginRight: '15px', 
                  fontSize:fontSize2, 
                  lineHeight: `${fontSize2 + 2 }px`,//'1',  
                  width: `250px` 
                }}>
                <p>
                Other information about this stat including the year of origin and relevant noted and maybe an assessment of how typical this percentage is.
                <br/><span style={{ color: '#ccc'}}>Source: Stats United</span> 
                </p>
              </div>
              
              <div 
                style={{ 
                  marginLeft: '0px', 
                  marginRight: '0px', 
                  fontSize:fontSize2, 
                  lineHeight: `${fontSize2 + 2 }px`,//'1', 
                  width: `calc(100% - 250px)`, 
                  position:"relative" 
                }}>   
                <Histogram 
                  key={`hist_${subIndicator.variable}_${index}`} 
                  dataArray={allData[activeGeography]?.dataArray[subIndicator.variable]}
                  histMax={allData[activeGeography]?.meta?.max_value[subIndicator.variable]}
                  histNA={allData[activeGeography]?.meta?.na_count[subIndicator.variable]} 
                  histLength={allData[activeGeography]?.meta?.length[subIndicator.variable]} 
                  chartId={subIndicator?.variable}
                  height={'200'}
                  //width={`calc(60% + ${(chartSize + 10) / 2}px)` }
                  width={'calc(100% - 0px)'}
                  paddingLeft={'0px'}
                  paddingRight={'0px'}
                  tooltips={true}
                  colorClass={options.category}
                />
              </div>
            </div>
          : null
      ))
      : null
  ), [chartIndicator, allData, activeGeography]);


  const memoizedSwarm = useMemo(() => (
    //console.log("D052724 allData", allData),
    //console.log("D052724 chartIndicator", chartIndicator),
    chartIndicator && chartIndicator.length > 0 
      ? chartIndicator.map((subIndicator: any, index: number) => (
        subIndicator && allData[activeGeography]?.dataArray[subIndicator?.variable]
          ?<div key={`swarm_${chartIndicator}_${index}`} ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight:"16px" }}>
          <div 
            style={{ 
              marginLeft: '0px', 
              marginRight: '0px', 
              fontSize:fontSize2, 
              lineHeight: '1', 
              width: "250px",//`calc(40% - ${(chartSize + 10)}px)` 
            }}>
            <div className="module-child-1-meta">
                <SwarmMeta
                  dataArray={allData[activeGeography]?.dataArray[subIndicator.variable]}
                  data={allData[activeGeography]}
                  indicator={subIndicator}
                />
            </div>
            <p
              style={{
                marginRight:"15px",
              }}
            >
            Other information about this stat including the year of origin and relevant noted and maybe an assessment of how typical this percentage is.
            <br/><span style={{ color: '#ccc'}}>Source: Stats United</span> 
            </p>
          </div>
          <div style={{ marginLeft: '0px', marginRight: '0px', fontSize:fontSize2, lineHeight: '1', width: `calc(100% - 250px)`, position:"relative" }}>        
          <Swarm 
              key={`swarm_${subIndicator.variable}_${index}`} 
              data={allData[activeGeography]}
              dataArray={allData[activeGeography]?.dataArray[subIndicator.variable]}
              histMax={allData[activeGeography]?.meta?.max_value[subIndicator.variable]}
              histMin={allData[activeGeography]?.meta?.min_value[subIndicator.variable]}
              histNA={allData[activeGeography]?.meta?.na_count[subIndicator.variable]} 
              histLength={allData[activeGeography]?.meta?.length[subIndicator.variable]} 
              chartId={subIndicator?.variable}
              indicator={subIndicator}
              colorClass={options.category}
            />
          </div>
          </div>
          : null
      ))
      : null
  ), [chartIndicator, allData, activeGeography, swarmCalibration]);

  const memoizedPie = useMemo(() => (
    <Pie
      key={`pie_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      colorClass={options.category}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width,*/ selectedId, key]);
  
  const memoizedList = useMemo(() => (
    <List
      key={`list_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      colorClass={options.category}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedPyramid = useMemo(() => (
    <Pyramid
      key={`pyramid_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={250}
      colorClass={options.category}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedStackedHistograms = useMemo(() => (
    <StackedHistograms
      key={`pyramid_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={"100%"}
      height={250}
      colorClass={options.category}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedMap = useMemo(() => (
    <GlobeMap
      key={`map_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={350}
      height={200}
      colorClass={options.category}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedDev = useMemo(() => (
    //console.log("A093024 chartIndicator", chartIndicator),
    //console.log("A093024 activeGeography", activeGeography),
    //console.log("A093024 options", options),
    <DeviationFromAverage
      key={`dev_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      colorClass={options.category}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedWaffle = useMemo(() => (
    <Waffle
      key={`waffle_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      colorClass={options.category}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width,*/ selectedId, key]);

  const memoizedTime = useMemo(() => (
    <Time
      key={`time_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      colorClass={options.category}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width,*/ selectedId, key]);

  const memoizedMiscellaneous = useMemo(() => (
    //console.log("A061924 chartIndicator", chartIndicator),
    //console.log("A061924 options", options),
    <div
      style={{
        height: "180px",
        //overflowY: "scroll",      
      }}
      key={`chart-card-misc-${index}`}
    >
      {options?.variables?.map((v: any, subIndex: number) => (
        <div
          key={`chart-card-${index}-${subIndex}`}
          style={{
            fontSize: "10px",
            color: "gray",      
          }}
          >{`${v.label} (${v.value})`}</div>
        ))}  
    </div>
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width*/]);

  const memoizedButtons = useMemo(() => (
    console.log("060724 chartIndicator", chartIndicator),
    <div 
      style={{
        height:(options.type === 'histogram') ? '65px' : '30px',
        width: '100%',
      }}
      key={`chart-card-buttons-${index}`}
    >{chartIndicator[0].label}
      <Button 
        variant={chartIndicator[0].variable == activeIndicator ? "secondary" : "outline-secondary"}
        style={{
          paddingTop: "0",
          paddingBottom: "0",
          marginLeft: "10px",
        }}
        size="sm"
        onClick={((e:any) => {
          handlePinVar1(e, chartIndicator[0].variable)
        })}>Pin 1</Button>
      <Button 
        variant={chartIndicator[0].variable == bivariateIndicator ? "secondary" : "outline-secondary"}
        style={{
          paddingTop: "0",
          paddingBottom: "0",
          marginLeft: "10px",
        }}
        size="sm"
        onClick={((e:any) => {
          handlePinVar2(e, chartIndicator[0].variable)
        })}>Pin 2</Button>
    </div>
  ), [/*allData,*/ chartIndicator, activeIndicator, /*activeGeography,*/ bivariateIndicator]);

  const memoizedHeader = useMemo(() => (
    //console.log("J092924 chartIndicator", chartIndicator),
    //console.log("J092924 chartIndicator[0]", chartIndicator[0]),
    <div 
    key={`chart-card-header-${index}`}
    style={{
      height:'30px',
      width: '100%',
      //paddingTop: '20px',
      borderBottom: '1px solid #ccc',
      //backgroundColor: "white", 
      overflowY: "hidden", //"scroll", 
      overflowX: "hidden", 
      //marginRight: "32px",
      //marginLeft: "32px",
      marginBottom: "10px",
      display: "inline-flex",
    }}
    >
      <SvgIcon
        svgName={chartIndicator[0].type === 'waffle' 
          ? 'person' 
          : chartIndicator[0].type === 'pie'
            ? "briefcase"
            : chartIndicator[0].type === 'deviation from average'
              ? "cap"
              : "calculator"
        }
      />
      <div
        style={{
          fontSize: "24px",
          lineHeight: "24px",
          color: "#231f20",
          marginLeft: "15px",
          overflowY: "hidden",
        }}
      >{`${chartIndicator[0].sort.Module} ${chartIndicator[0].geo}`/*.split("(")[0]*/}</div>
      {/*>{chartIndicator[0].sort.DISPLAY_NAME.split("(")[0]}</div>*/}

      {/*<div
        style={{
          fontSize: "12px",
          lineHeight: "28px",
          paddingLeft:"5px",
          color: "#5a5a5a",
        }}
      >{` (${chartIndicator[0].sort.Module.split("(")[1]}`}</div>*/}
    </div>
  ), [/*allData,*/ chartIndicator, activeIndicator, /*activeGeography,*/ bivariateIndicator]);

  /*console.log("D052724 options", options.type.toLowerCase());
  console.log("A071024 options", options.variables[0].sort.provenance);*/
  return (
    <div
      key={`chart-card-${index}-${chartIndicator}`}
      ref={ref}
      style={{
        height: "100%", //'200px',
        //paddingTop: '20px',
        //borderTop: '1px solid #ccc',
        backgroundColor: "white", 
        overflowY: "hidden", //"scroll", 
        overflowX: "hidden", 
        marginRight: "32px",
        marginLeft: "32px",
      }}>
       {/* <div
          style={{
            fontSize: "10px",
            lineHeight: "19px",
            color: "gray",
            backgroundColor: "white",
            position:"absolute",
            top: "1px",
            height:"19px",
            borderBottom: "1px solid #e8e4e4",
            width: "100%",
            zIndex: "1",
          }}
        >{chartIndicatorName}</div>*/}
      {/* options.variables[0].sort.provenance && 
      (() => {
          switch (options.type.toLowerCase()) {
            case 'histogram':
              return memoizedButtons;
            case 'beeswarm':
              return memoizedButtons;
            default:
              return null;
          }
        })()
      */}
      { memoizedHeader }
      { (() => {
          switch (options.type.toLowerCase()) {
            case 'histogram':
              return memoizedHistogram;
            case 'beeswarm':
              return memoizedSwarm;
            case 'pie':
              return memoizedPie;
            case 'waffle':
              return memoizedWaffle;
            case 'list':
              return memoizedList;
            case 'ranked list':
              return memoizedList;
            case 'pyramid':
              return memoizedPyramid;
            case 'stacked histograms':
              return memoizedStackedHistograms;
            case 'map':
              return memoizedMap;
            case 'time':
              return memoizedTime;
            case 'deviation from average':
              return memoizedDev;
            default:
              return memoizedMiscellaneous;
          }
        })()}
    </div>
  )
};
