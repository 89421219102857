import React, { useState, useEffect, useMemo } from 'react';
import { useGlobalSelectedId } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';

export interface TimeChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass:string;
}

const colors = Colors.wafflePieColors;

const Time: React.FC<TimeChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const selectedId = useGlobalSelectedId();
  console.log("A061924 Time options", options);
  console.log("A061924 Time data", data);
  console.log("A061924 selectedId", selectedId)
  useEffect(() => {
    console.log("A061924 Time selectedId changed", selectedId)
    if (selectedId){
      //prepData();
    }
  }, [selectedId]);

  useEffect(() => {
    console.log("A061924 Time selectedId", selectedId)
    if (selectedId){
      //prepData();
    }
  }, []);

  
  return (
    <div style={{ position: "relative", width, height }}>
      TIME 
    </div>
  );
};

export default Time;
