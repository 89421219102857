import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';

const CreatePanes: React.FC = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return; // Ensure map is available before proceeding

    // Create and style the vector tile pane
    const vectorTilePane = map.createPane('vectorTilePane');
    if (vectorTilePane) {
      vectorTilePane.style.zIndex = '100';
    }

    // Create and style the vector tile label pane
    const vectorTileLabelPane = map.createPane('vectorTileLabelPane');
    if (vectorTileLabelPane) {
      vectorTileLabelPane.style.zIndex = '650';
      vectorTileLabelPane.style.pointerEvents = 'none';
    }

    // No need for cleanup as Leaflet handles pane removal automatically

  }, [map]);

  return null;
};

export default CreatePanes;
