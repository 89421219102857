import React, { useState } from 'react';
import Colors from '../utilities/Colors';
// Import the functions directly from the store
import { Histogram } from '../charts/Histogram';
import { onHoverHistogram, useGlobalActiveGeography, useGlobalActiveIndicator } from '../../data/StatusStore';
import ExportModal from '../inputs/ExportModal';

interface DataItem {
    id: string;
    value: any; // Consider specifying a more detailed type here
}

export interface MainHistogramProps {
  dataGeo: any;
  histMax: any;
  histNA: any;
  histLength: any; 
  chartId: string;
}

export const MainHistogram: React.FC<MainHistogramProps> = ({ 
    dataGeo, histMax, histNA, histLength, chartId
}) => {
    console.log("B092524 histMax", histMax);
    console.log("B092524 dataGeo", dataGeo);
    console.log("B092524 chartId", chartId);
    const activeIndicator = useGlobalActiveIndicator();
    const activeGeography = useGlobalActiveGeography();

    const [isModalVisible, setModalVisible] = useState(false);

    const openModal = () => setModalVisible(true);
    const closeModal = () => setModalVisible(false);

    const height = '135';
    const width = '100%'; //calc(100% - 40px)';
    const paddingLeft = '0px'; 
    const paddingRight = '0px'; 
    console.log("083024 dataGeo", dataGeo);
    console.log("083024 activeIndicator", activeIndicator);
    console.log("083024 dataGeo?.meta?.DISPLAY_NAME", dataGeo?.meta?.DISPLAY_NAME);
    console.log("083024 dataGeo?.meta.DISPLAY_NAME[activeIndicator]", dataGeo?.meta.DISPLAY_NAME[activeIndicator]);
    return (
        <>       
            <div style={{ 
                padding:"25px",
                paddingBottom:"0px",
                display: "inline-flex",
                height:'calc(100% - 135px)',
            }}>
                <div style={{  
                        
                    }}>
                    <div style={{ 
                        //fontWeight:"bold",
                        height:'30px',
                        width: '100%', 
                        overflowY: "hidden", 
                        overflowX: "hidden", 
                        display: "inline-flex",
                    }}>
                        <div
                            style={{
                            fontSize: "24px",
                            lineHeight: "24px",
                            color: "#231f20",
                            overflowY: "hidden",
                            }}
                        >{dataGeo?.meta.DISPLAY_NAME[activeIndicator] ? dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[0] : ""}</div>
                        {/*<div
                            style={{
                            fontSize: "12px",
                            lineHeight: "28px",
                            paddingLeft:"5px",
                            color: "#5a5a5a",
                            }}
                        >{` (${dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[1]}`}</div>  */}  
                    </div> 
                    <div style={{ fontSize:"12px", width: '100%', paddingTop:"5px", paddingBottom:"10px", lineHeight:"14px"}}>for <b>all people</b> by <b>Neighborhood Tabulation Area</b></div>
                    <div 
                        style={{ 
                            fontSize:"12px", 
                            width: '100%', 
                            marginBottom:"10px", 
                            lineHeight:"14px",
                            overflow: "hidden",
                            height: "calc(100% - 105px)",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "6", /* Number of lines to show before truncating */
                            WebkitBoxOrient: "vertical",
                        }}>The term "prime age" typically refers to the age range at which individuals are considered to be in their most productive years, often in the context of the labor market. The specific age range that is considered "prime" can vary depending on the source, but it generally falls within the range of 25-54 years old.</div>
                    <div style={{ fontSize:"12px", width: '100%', lineHeight:"14px"}}>
                        <span style={{ 
                            color:"#9E9C9C",
                            //fontWeight:"bold",
                        }}
                        >Source: Measure of America</span>
                        <span style={{
                            paddingLeft:"15px",
                        }}></span>
                        <span
                            onClick={() => {
                                alert(`this will bring up a modal displaying the indicator(${activeIndicator}'s) metadata`)
                            }}
                            style={{
                                fontStyle:"italic",
                                textDecoration:"underline",
                                textUnderlineOffset:"1px",
                                //fontWeight:"bold",
                                cursor: "pointer",
                            }}
                        >more info...</span></div>
                </div>
                <div>
                    <div style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingRight:"2.5px",
                            paddingLeft:"2.5px",
                            lineHeight:"16px",
                            height:"20px",
                            cursor: "pointer",
                        }}
                        className='compare-button'
                        onClick={() => {
                            alert(`this will bring you to the /compare page with the current indicator (${activeIndicator}) and geography (${activeGeography}) selected`)
                        }}
                    >compare</div>
                    <div style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingRight:"2.5px",
                            paddingLeft:"2.5px",
                            marginTop:"5px",
                            lineHeight:"16px",
                            height:"20px",
                            cursor: "pointer",
                        }}
                        className='compare-button'
                        onClick={() => {
                            //alert(`this will begin an export`)
                            openModal();
                        }}
                    >export/share</div>
                    <ExportModal 
                        title={dataGeo?.meta.DISPLAY_NAME[activeIndicator] ? dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[0] : ""} 
                        isVisible={isModalVisible} 
                        onClose={closeModal} 
                        dataGeo={dataGeo}
                        activeIndicator={activeIndicator}
                        bivariateIndicator={null}
                    />
                    </div>
            </div>
            <div style={{ 
                //paddingLeft:"25px",
                //paddingRight:"25px",
                //position:"relative",
                width: "calc(100% - 50px)",
                position:"relative",
                left:"25px",
            }}>
                <Histogram 
                    key={`main_hist_${activeIndicator}`}
                    dataArray={dataGeo?.dataArray[activeIndicator]}
                    histMax={histMax[activeIndicator]}
                    histNA={histNA[activeIndicator]} 
                    histLength={histLength[activeIndicator]} 
                    chartId={activeIndicator}
                    height={height}
                    width={width}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    tooltips={true}
                    colorClass={"Basics"}
                />
            </div>
        </>
    );
};
