import React, { useState, useEffect, useMemo } from 'react';
import { useGlobalSelectedId, useGlobalHoveredId } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import { Histogram } from '../charts/Histogram';
import { onHoverHistogram, useGlobalActiveGeography, useGlobalActiveIndicator } from '../../data/StatusStore';
import { index } from 'd3';
import Colors from '../utilities/Colors';

export interface StackedHistogramsChartProps {
  options: any;
  data: any;
  width: string;
  height: number;
  colorClass:string;
}

const StackedHistograms: React.FC<StackedHistogramsChartProps> = ({ options, data, width, height, colorClass }) => {
  
  console.log("%StackedHistograms 1 options", options);
  const [preppedData, setPreppedData] = useState<any>([]);
  const [indexLookup, setIndexLookup] = useState<any>({});
  const selectedId = useGlobalSelectedId();
  const hoveredId = useGlobalHoveredId();
  const activeGeography = useGlobalActiveGeography();
  const _height = '80';
  useEffect(() => {
    if (selectedId){
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const _preppedData = options.variables.map((v: any) => {
        return { name: v.label, value: data.dataJSON[selectedId][v.variable] };
      });
      setPreppedData(_preppedData);
    }
  };

  const setIndexes = (index:number, variable:string ) => {
    //console.log("B083124 setIndexes index", index);
    //console.log("B083124 setIndexes variable", variable);
    setIndexLookup((prevIndexLookup: any) => ({
      ...prevIndexLookup, // Spread the existing state
      [variable]: index,       // Add or update the new key/value
    }));
  };

  //const total = preppedData.reduce((acc: number, d: any) => acc + d.value, 0);
  //const gridSize = 10; // 10x10 grid
  //const fontSize = 12; // For dynamic heights --> // Math.min(12, Math.max(8, dimensions.width / 50));
  const fontSize2 = 12; // For dynamic heights --> // Math.min(10, Math.max(8, dimensions.width / 50));
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  // Define the bar width in viewBox units for consistency
  let dataLen = data?.dataArray.GEO_ID ? data?.dataArray.GEO_ID.length : 59;
  const barWidthViewBoxUnits = dataLen
    ? dataLen > 100 
      ? 27 
      : 9
    : 27; // Adjust based on number of bars

  // Memoize the Histogram components
  const memoizedHistograms = useMemo(() => {
    return options.variables.map((d: any, i: number) => {
      return (
        <Histogram 
          key={`stacked_hist_${d.variable}_${i}`} 
          dataArray={data?.dataArray[d.variable]}
          histMax={data?.meta?.max_value[d.variable]}
          histNA={data?.meta?.na_count[d.variable]} 
          histLength={data?.meta?.length[d.variable]} 
          chartId={`${d.variable}_stacked_hist`}
          height={_height}
          width={'calc(100%)'}
          paddingLeft={'0px'}
          paddingRight={'0px'}
          tooltips={false}
          colorClass={colorClass}
        />
      );
    });
  }, [options.variables, data]);

  return (
    <div style={{ position: "relative", width, height }}>
        {options.variables.map((d: any, i: number) => {
          let selectedIndex = 0;
          let nextSelectedIndex = 0;
          let hoveredIndex = 0;
          let nextHoveredIndex = 0;
          let selectedBarHeight = 0;
          let hoveredBarHeight = 0;
          let _selected = null;
          let _hovered = null;
          if (d && data?.dataArray[d.variable]) {
            //console.log("083124 data?.dataArray[d.variable]", data?.dataArray[d.variable]);
            //console.log("083124 data?.keyIndexDataArray[d.variable]", data?.keyIndexDataArray[d.variable]);
            if (selectedId) {
              //console.log("083124 data?.keyIndexDataArray[d.variable]", data?.keyIndexDataArray[d.variable][selectedId]);
              _selected = data?.keyIndexDataArray[d.variable][selectedId];
              selectedIndex = _selected?.index;
              let _nextSelected = options.variables[i+1] ? data?.keyIndexDataArray[options.variables[i+1].variable][selectedId] : null;
              nextSelectedIndex = _nextSelected ? _nextSelected.index : 0;
              selectedBarHeight = _nextSelected
                ? _nextSelected.value === "NA" ? 0 : (_nextSelected.value / data?.meta.max_value[options.variables[i+1].variable]) * parseFloat(_height)
                : 0; 
            }
            if (hoveredId) {
              //console.log("083124 data?.keyIndexDataArray[d.variable]", data?.keyIndexDataArray[d.variable][hoveredId]);
              _hovered = data?.keyIndexDataArray[d.variable][hoveredId];
              hoveredIndex = _hovered?.index;
              let _nextHovered = options.variables[i+1] ? data?.keyIndexDataArray[options.variables[i+1].variable][hoveredId] : null;
              nextHoveredIndex = _nextHovered ? _nextHovered.index : 0;
              hoveredBarHeight = _nextHovered
                ? _nextHovered.value === "NA" ? 0 : (_nextHovered.value / data?.meta.max_value[options.variables[i+1].variable]) * parseFloat(_height)
                : 0;
            }
            //console.log("083124 data", data);
            //console.log("083124 d", d);
          }
          //console.log("E083124 hoveredBarHeight", hoveredBarHeight);
          //console.log("E083124 selectedBarHeight", selectedBarHeight);
          // Define coordinates dynamically based on the position and data
          let x1Index = hoveredIndex;
          let x2Index = nextHoveredIndex;
          let x1IndexS = selectedIndex;
          let x2IndexS = nextSelectedIndex; 
          let x1 = `calc(${((x1Index) / dataLen) * 100}% + ${10 - (barWidthViewBoxUnits / 2)}px)`; 
          let y1 = `calc(${50}% - ${20}px)`; 
          let x2 = `calc(${(x2Index) / dataLen * 100}% + ${10 - (barWidthViewBoxUnits / 2)}px)`; 
          let y2 = `calc(${100}% - ${hoveredBarHeight - 10}px)`;
          let x1S = `calc(${((x1IndexS) / dataLen) * 100}% + ${10 - (barWidthViewBoxUnits / 2)}px)`; 
          let y1S = `calc(${50}% - ${20}px)`; 
          let x2S = `calc(${(x2IndexS) / dataLen * 100}% + ${10 - (barWidthViewBoxUnits / 2)}px)`; 
          let y2S = `calc(${100}% - ${selectedBarHeight - 10}px)`;
 
          return (
            d && data?.dataArray[d.variable] ? (
              <div 
                ref={containerRef} 
                style={{ width: '100%', height: '100px', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight: "16px" }}
                key={`stacked-chart-card-hist-${i}`}
              >
                <div 
                  style={{ 
                    marginLeft: '0px',
                    marginRight: '15px', 
                    fontSize: fontSize2, 
                    lineHeight: `${fontSize2 + 2}px`,
                    width: `250px` 
                  }}>
                  <p>
                  Other information about this stat including the year of origin and relevant notes and maybe an assessment of how typical this percentage is.
                  <br/><span style={{ color: '#ccc'}}>Source: Stats United</span> 
                  </p>
                </div>
                <div 
                  style={{ 
                    marginLeft: '0px',
                    marginRight: '15px', 
                    fontSize: fontSize2, 
                    lineHeight: `${fontSize2 + 2}px`,
                    width: `100px`, 
                    display: 'flex',
                  }}>
                    <div style={{ 
                      width: '50%', 
                      height: '100%', 
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '15px',
                    }}>
                      {_hovered 
                        ? d2gRound(_hovered.value) 
                        : _selected 
                          ? d2gRound(_selected.value)
                          : "N/A"}
                    </div>
                    <div style={{ 
                      width: '50%', 
                      height: '100%', 
                      textAlign: 'left',
                    }}>
                       {d.label.split("(")[0]}
                    </div>
                </div>
                <div 
                  style={{ 
                    marginLeft: '0px', 
                    marginRight: '0px', 
                    fontSize: fontSize2, 
                    lineHeight: `${fontSize2 + 2}px`, 
                    width: `calc(100% - 350px)`, 
                    position: "relative" 
                  }}>    
                  {/* Add SVG to draw slanted line */}
                  {i + 1  < options.variables.length
                  ? <svg key={`${d.variable}_${selectedId}_${activeGeography }`} width="100%" height={`calc(${200}%`} style={{ position: 'absolute', top: '10px', left: 0, pointerEvents: 'none', zIndex: 1 }}>
                    <line 
                      x1={x1S} y1={y1S} x2={x2S} y2={y2S} 
                      stroke={Colors.highlightSelect} 
                      strokeWidth="1" 
                      opacity={0.5}
                      strokeDasharray="2,2" // Optional: Makes the line dashed
                    />
                    { hoveredId
                    ? <line 
                        x1={x1} y1={y1} x2={x2} y2={y2} 
                        stroke={Colors.highlightHover} 
                        strokeWidth="1" 
                        strokeDasharray="2,2" // Optional: Makes the line dashed
                      />
                      : null}
                  </svg>
                  : null}
                  {memoizedHistograms[i]}
                </div>
              </div>
            ) : null
          );
        })}
    </div>
  );
};

export default StackedHistograms;
