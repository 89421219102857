import React, { useState, useEffect, useRef } from 'react';
import Colors from '../utilities/Colors';
import { useGlobalHoveredId, useGlobalSelectedId } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';

interface SwarmChartMetaProps {
  dataArray: DataItem[];
  indicator: any;
  data: any;
}

interface DataItem {
  id: string;
  value: number;
  x?: number;
  y?: number;
}

const SwarmDynamic: React.FC<SwarmChartMetaProps> = ({
  dataArray,
  indicator,
  data
}) => {

  const hoveredId = useGlobalHoveredId();
  const selectedId = useGlobalSelectedId() as any;

  return (<div className='module-meta-stat-label'>       
            <div className="module-meta-stat">
              {selectedId && indicator && indicator.variable ? d2gRound(data.dataJSON[selectedId][indicator.variable], { returnString: true }) : "NA"}
            </div>
            {/*<div className="module-meta-label">
              {indicator.label}
            </div>*/}
          </div>);
};

export default SwarmDynamic;
