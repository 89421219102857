// DataContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import Papa, { ParseResult } from 'papaparse';
import { feature } from 'topojson-client';
import { FeatureCollection, GeoJsonObject } from 'geojson';
import { useGlobalActiveGeography } from '../data/StatusStore';
import { crosswalkPUMAtoCD } from '../components/utilities/Utilities';
import { cross } from 'd3';

// Define the type for a row in your CSV data
// Update this interface according to the actual structure of your CSV data
export interface FlatFileCsvData {
  meta: any;
  dataJSON: any;
  dataArray: any;
  keyIndexDataArray: any;
}

// Define the type for your context data
interface DataContextType {
  dataActive: FlatFileCsvData | null;
  dataTract: FlatFileCsvData | null;
  dataPUMA: FlatFileCsvData | null;
  dataNYC: FlatFileCsvData | null;
  dataCD: FlatFileCsvData | null;
  dataBoro: FlatFileCsvData | null;
  dataSorter: any | null;
  geoJsonDataGlobe: FeatureCollection | null;
  geoJsonDataTract: any;
  geoJsonDataPUMA: FeatureCollection | null;
  isLoadingTract: boolean;
  isLoadingPUMA: boolean;
  isLoadingNYC: boolean;
  isLoadingCD: boolean;
  isLoadingBoro: boolean;
  isLoadingAllTabularData: boolean;
  isLoadingSorter: boolean;
  isLoadingTopology: boolean;
  isLoadingGlobeTopology: boolean;
  error: string | null;
}

interface DataProviderProps {
  children?: any; // ReactNode;
  title: string; // An example of an additional prop
}

// Create the context with initial default value
const DataContext = createContext<DataContextType>({ 
  dataActive: null, 
  dataTract: null, 
  dataPUMA: null, 
  dataNYC: null, 
  dataCD: null, 
  dataBoro: null, 
  dataSorter: null, 
  geoJsonDataTract: null, 
  geoJsonDataGlobe: null, 
  geoJsonDataPUMA: null, 
  isLoadingTract: true, 
  isLoadingPUMA: true, 
  isLoadingNYC: true, 
  isLoadingCD: true, 
  isLoadingBoro: true, 
  isLoadingAllTabularData: true, 
  isLoadingSorter: true, 
  isLoadingTopology: true, 
  isLoadingGlobeTopology: true, 
  error: null 
});

// Create a provider component
export const DataProvider: React.FC<DataProviderProps> = ({ children, title }) => {
  const [dataActive, setDataActive] = useState<FlatFileCsvData | null>(null);
  const [dataTract, setDataTract] = useState<FlatFileCsvData | null>(null);
  const [dataPUMA, setDataPUMA] = useState<FlatFileCsvData | null>(null);
  const [dataCD, setDataCD] = useState<FlatFileCsvData | null>(null);
  const [dataNYC, setDataNYC] = useState<FlatFileCsvData | null>(null);
  const [dataBoro, setDataBoro] = useState<FlatFileCsvData | null>(null);
  const [dataSorter, setDataSorter] = useState<any | null>(null);
  const [dataSorterMeta, setDataSorterMeta] = useState<any | null>(null);
  const [geoJsonDataGlobe, setGeoJsonDataGlobe] = useState<any>(null);
  const [geoJsonDataTract, setGeoJsonDataTract] = useState<FeatureCollection  | null>(null);
  const [geoJsonDataPUMA, setGeoJsonDataPUMA] = useState<FeatureCollection  | null>(null);
  const [crosswalkedPUMAData, setCrosswalkedPUMAData] = useState<any>(null);
  const [rawCDData, setRawCDData] = useState<any>(null);
  const [isLoadingTract, setIsLoadingTract] = useState<boolean>(true);
  const [isLoadingPUMA, setIsLoadingPUMA] = useState<boolean>(true);
  const [isLoadingNYC, setIsLoadingNYC] = useState<boolean>(true);
  const [isLoadingCD, setIsLoadingCD] = useState<boolean>(true);
  const [isLoadingBoro, setIsLoadingBoro] = useState<boolean>(true);
  const [isLoadingAllTabularData, setIsLoadingAllTabularData] = useState<boolean>(true);
  const [isLoadingSorter, setIsLoadingSorter] = useState<boolean>(true);
  const [isLoadingTopology, setIsLoadingTopology] = useState<boolean>(true);
  const [isLoadingGlobeTopology, setIsLoadingGlobeTopology] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const activeGeography = useGlobalActiveGeography();

  useEffect(() => {
    console.log('Active geography:', activeGeography);
    switch (activeGeography) {
      case 'Tract':
        setDataActive(dataTract);
        break;
      case 'PUMA':
        //setDataActive(dataPUMA); // PUMA data is now crosswalked to CD
        setDataActive(dataCD);
        break;
      case 'NYC':
        setDataActive(dataNYC);
        break;
      case 'CD':
        setDataActive(dataCD);
        break;
      case 'Boro':
        setDataActive(dataBoro);
        break;
      default:
        setDataActive(null);
        break;
    }
  }, [activeGeography]);

  useEffect(() => {
    if (!isLoadingBoro && !isLoadingCD && !isLoadingNYC && !isLoadingPUMA && !isLoadingTract && !isLoadingSorter){
      console.log('All data loaded');
      setIsLoadingAllTabularData(false);
    }
  }, [isLoadingPUMA, isLoadingCD, isLoadingBoro, isLoadingNYC, isLoadingTract]);

  useEffect(() => {
    console.log('F093024 crosswalkedPUMAData:', crosswalkedPUMAData);
    console.log('F093024 rawCDData:', rawCDData);
    /*if (crosswalkedPUMAData && rawCDData) {
        rawCDData.map((row: any, indexCD:number) => {
          console.log('B081824 row:', row);
          let _pumaRow = crosswalkedPUMAData.filter((crosswalkedRow: any, index:number) => {
            if (row.GEO_ID !== ""){
              return crosswalkedRow.GEO_ID === row.GEO_ID;
            }else{
              return indexCD === index;
            }
          })[0];
          console.log('B081824 _pumaRow:', _pumaRow);
          if (_pumaRow){
            Object.keys(_pumaRow).forEach((key: string) => {
              row[key] = _pumaRow[key];
            });
          };
        });
        const concatPUMA_CD = rawCDData; //rawCDData.concat(crosswalkedPUMAData);
        console.log('G092424 concatPUMA_CD:', concatPUMA_CD);
        const transformedPUMA_CD = transformData(concatPUMA_CD, "_cd");
        console.log('C092524 transformedPUMA_CD:', transformedPUMA_CD);
        setDataPUMA(transformedPUMA_CD);
        setDataCD(transformedPUMA_CD);
        setIsLoadingCD(false);
        setIsLoadingPUMA(false);
    }*/
  }, [rawCDData, crosswalkedPUMAData]);


  useEffect(() => {
    Papa.parse<FlatFileCsvData>("/data/tabular/NYCDATA2GO_sorter.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        //const transformedData = transformData(result.data);
        
        const data = result.data;
        //if (result.data){
          let __meta: { [key: string]: any } = {};
          result.data.forEach((row:any, i:number) => {
            //if (i < 10){
              //console.log("B092424 row", row);  
              Object.keys(row).forEach((item:any, ii:number) => {
                //console.log("B092424 item", item);
                //console.log("B092424 row[item]", row[item]);
                //console.log("B092424 row[VARIABLE_NAME]", row["VARIABLE_NAME"]);
                let _key = item;//row["VARIABLE_NAME"];
                let _obj = { [row["VARIABLE_NAME"]]: row[item] };
                //console.log("B092424 _obj", _obj);
                //let _key = item; //row["VARIABLE_NAME"];
                if (__meta[_key]) {
                    __meta[_key] = { ...__meta[_key], ..._obj };
                } else {
                  __meta[_key] = _obj;
                }
              });
            //}
          });
          console.log("B092424 __meta", __meta);
          setDataSorterMeta(__meta);
        //}   
        // Function to dynamically transform each row of data
        //const transformData = (data: any) => {
          // Create a new object to store the transformed data
          //const transformedObject: any = {};
    
          // Loop through each key-value pair in the current row
          /*data.forEach((line: any) => {
            console.log('B092324 line', line);
            if (meta["VARIABLE_NAME"]) {
              meta["VARIABLE_NAME"] = { ...meta["VARIABLE_NAME"], ...row };
            } else {
              meta["VARIABLE_NAME"] = row;
            }
          });*/
    
          //return transformedObject;
        //};
    
        // Apply the transformation to all rows in the dataset
        //const transformedData = transformData(data); //data.map(row => transformData(row));
    
        // Output or further process the transformed data as required
        //console.log(transformedData);
    
        console.log('B092324 data', data); // Now the data is transposed
        //console.log('B092324 transformedData', transformedData); // Now the data is transposed
        //console.log('B092324 dataSorterMeta', dataSorterMeta);
        setDataSorter(result.data);
        setIsLoadingSorter(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingSorter(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoadingSorter && dataSorterMeta) {
    // Example CSV file loading and parsing
    // Ensure to replace "path/to/your/data.csv" with the actual path to your CSV file
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_Tract.csv", {
    //Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - Tract.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        const transformedData = transformData(result.data, "_tract");
        console.log('C092524 Tract data loaded:', transformedData);
        //setDataActive(transformedData); // Tract is default upon load
        setDataTract(transformedData);
        setIsLoadingTract(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingTract(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_Puma.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        console.log('G092524 PUMA data raw:', result.data);
        const _crosswalkedPUMAData = crosswalkPUMA(result.data);
        setCrosswalkedPUMAData(_crosswalkedPUMAData);
        const transformedData = transformData(_crosswalkedPUMAData, "_puma");
        console.log('C081624 PUMA data loaded:', transformedData);
        setDataPUMA(transformedData);
        setIsLoadingPUMA(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingPUMA(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - NYC.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        const transformedData = transformData(result.data, "_nyc");
        setDataNYC(transformedData);
        setIsLoadingNYC(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingNYC(false);
      }
    });
    //Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - CD.csv", {
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_CD.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        setRawCDData(result.data);
        const transformedData = transformData(result.data, "_cd");
        console.log('G092524 CD data loaded:', transformedData);
        setDataActive(transformedData);
        setDataCD(transformedData);
        setIsLoadingCD(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingCD(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - Boro.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        const transformedData = transformData(result.data, "_boro");
        setDataBoro(transformedData);
        setIsLoadingBoro(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingBoro(false);
      }
    });
    fetch('/data/geo/ALL_GEO_2020.json')
    .then(response => response.json())
    .then((topojsonData: any) => {
      console.log('Topojson data loaded:', topojsonData);
      //const geoJsonTract: FeatureCollection = feature(topojsonData, topojsonData.objects.TRACT_2010) as any;
      const geoJsonTract: FeatureCollection = feature(topojsonData, topojsonData.objects.Tract) as any;
      //const geoJsonPUMA: FeatureCollection = feature(topojsonData, topojsonData.objects.PUMA_2010) as any;
      let lookup: any = {
        MN01:101,
        MN02:102,
        MN03:103,
        MN04:104,
        MN05:105,
        MN06:106,
        MN07:107,
        MN08:108,
        MN09:109,
        MN10:110,
        MN11:111,
        MN12:112,
        BX01:201,
        BX02:202,
        BX03:203,
        BX04:204,
        BX05:205,
        BX06:206,
        BX07:207,
        BX08:208,
        BX09:209,
        BX10:210,
        BX11:211,
        BX12:212,
        BK01:301,
        BK02:302,
        BK03:303,
        BK04:304,
        BK05:305,
        BK06:306,
        BK07:307,
        BK08:308,
        BK09:309,
        BK10:310,
        BK11:311,
        BK12:312,
        BK13:313,
        BK14:314,
        BK15:315,
        BK16:316,
        BK17:317,
        BK18:318,
        QN01:401,
        QN02:402,
        QN03:403,
        QN04:404,
        QN05:405,
        QN06:406,
        QN07:407,
        QN08:408,
        QN09:409,
        QN10:410,
        QN11:411,
        QN12:412,
        QN13:413,
        QN14:414,
        SI01:501,
        SI02:502,
        SI03:503
      };
      //console.log('C093024 topojsonData.objects.CD', topojsonData.objects.CD);
      let newGeometries:any = [];
      topojsonData.objects.CD.geometries.forEach((geometry: any) => {
        const originalGEOID = geometry.properties.GEOID20;
        if (lookup[originalGEOID]) {
          //console.log('C093024', originalGEOID);
          geometry.properties.GEOID20 = lookup[originalGEOID];
          newGeometries.push(geometry);
        }else{
          //console.log('C093024 Removing geometry:', originalGEOID);
          topojsonData.objects.CD.geometries = topojsonData.objects.CD.geometries.filter((g: any) => g.properties.GEOID20 !== originalGEOID);
          //console.log('C093024 CD data lookup failed:', originalGEOID);
        }
      });
      //console.log('C093024 topojsonData.objects.CD', topojsonData.objects.CD);

      console.log('F092424 CD data lookup:', lookup);
      const geoJsonPUMA: FeatureCollection = feature(topojsonData, topojsonData.objects.CD) as any;
      setGeoJsonDataTract(geoJsonTract);
      setGeoJsonDataPUMA(geoJsonPUMA);
      setIsLoadingTopology(false);
    })
    .catch((err) => {
      console.error('Error loading the topojson data:', err)
      setError(err.message);
      setIsLoadingTopology(false);
    });
    fetch('/data/geo/worldCleanGeoJSONCleaned.json')
    .then(response => response.json())
    .then((topojsonGlobeData: any) => {
      console.log('061024 Topojson globe data loaded:', topojsonGlobeData);
      const geoJsonGlobe: FeatureCollection = feature(topojsonGlobeData, topojsonGlobeData.objects.collection) as any;
      setGeoJsonDataGlobe(geoJsonGlobe);
      setIsLoadingGlobeTopology(false);
    })
    .catch((err) => {
      console.error('Error loading the global topojson data:', err)
      setError(err.message);
      setIsLoadingGlobeTopology(false);
    });
  }
  }, [isLoadingSorter]);

  function crosswalkPUMA(rawData: any[]): any {
    console.log("G092524, rawData", rawData);
    let crossWalkedData = rawData.flatMap((row: any, i: number) => {
      row["GEO_ID_PUMA"] = row["GEO_ID"];
      if (row.GEO_ID === "") {
        console.log("081624, row", row);
        return row;  // Will be flattened to a single element
      } else {
        console.log("B092524, row", row);
        let _CDs = crosswalkPUMAtoCD(row["GEO_ID"]);
        //console.log("G092524, _CDs", _CDs);
        console.log("A081624, row", row);
        if (_CDs?.length > 1) {
          const copies = _CDs.map((cd: string) => {
            console.log("A081624, cd", cd);
            const copy = { ...row };
            console.log("A081624, copy", copy);
            copy["GEO_ID"] = cd;
            return copy;
          });
          return copies;  // Array of copies will be flattened into the result
        } else {
          row["GEO_ID"] = _CDs[0];
          return row;  // Will be flattened to a single element
        }
      }
    });
  
    console.log("G092524, crossWalkedData", crossWalkedData);
    return crossWalkedData;
  }
  

  function transformData(rawData: any[], caller:string): FlatFileCsvData {
    //let __meta: { [key: string]: any } = dataSorter ? dataSorter[0] : null;
    let meta: { [key: string]: any } = { ...dataSorterMeta };
    let dataJSON: { [key: string]: any } = {};
    let dataArray: { [key: string]: any[] } = {};
    let keyIndexDataArray: { [key: string]: any[] } = {};

    console.log("C092524 rawData", rawData);
    console.log("III092424 caller", caller);
    console.log("II092424 dataSorterMeta", dataSorterMeta);
    console.log("II092424 meta", meta);
    rawData.forEach((row:any, i:number) => {
      if (row.GEO_ID === ""){
        const _key = !(row["Base Variable"] === "") ? row["Base Variable"] : `Row_${i}`;
        //console.log("D092324 _key", _key);
        //console.log("D092324 row", row);
        /*if (meta[_key]) {
          meta[_key] = { ...meta[_key], ...row };
        } else {
          meta[_key] = row;
        }*/
      }else{
        Object.keys(row).forEach(key => {
          // Check if the value is a number by trying to parse it
          let newKey = key.replace(caller,"");
          let _val = row[key];
          if (key !== "GEO_ID"){
            //console.log(key);
            const parsedValue = parseFloat(_val);
            // If the parsed value is a number and is not NaN, use it; otherwise, keep the original value
            _val = !isNaN(parsedValue) ? parsedValue : row[key];
            row[newKey] = _val;
          }
          dataArray[newKey] ? dataArray[newKey].push({ id: row["GEO_ID"], value: _val }) : dataArray[newKey] = [{ id: row["GEO_ID"], value: _val }];
        });
        dataJSON[row.GEO_ID] = row;
      }
    });
    console.log("C092524 dataArray", dataArray);
    //console.log("A092424 rawData", rawData);
    //console.log("A092424 dataSorter", dataSorter);
    //console.log("B092424 meta", meta);
    Object.keys(dataArray).forEach(key => {
      dataArray[key].sort((a, b) => {
        let _a = a.value;
        let _b = b.value;
        if (a.value === "NA" ){
          _a = -999999999
        }
        if (b.value === "NA" ){
          _b = -999999999
        }
        return _a - _b
      });
      let histMax = -Infinity; // Start with the lowest possible number
      let histMin = Infinity; // Start with the highest possible number
      let histNA = 0; // Count of "NA" values
      let histLength = 0; // Count of "NA" values
      let total = 0; // Count of "NA" values
      const keyIndex = dataArray[key].reduce((accumulator:any, currentValue:any, index:number) => {
        if (currentValue.value === "NA") {
          histNA += 1;
        } else {
          const value = parseFloat(currentValue.value);
          total += value;
          if (!isNaN(value) && value > histMax) {
        histMax = value;
          }
          if (!isNaN(value) && value < histMin) {
        histMin = value;
          }
        }
        accumulator[currentValue.id] = {value: currentValue.value, index: index};
        return accumulator;
      }, {});

      keyIndexDataArray[key] = keyIndex;
      //console.log("III092424 meta['length']", meta["length"]);
      //console.log("III092424 dataArray", dataArray);
      if (!meta["max_value"]) { meta["max_value"] = {} };
      if (!meta["min_value"]) { meta["min_value"] = {} };
      if (!meta["na_count"]) { meta["na_count"] = {} };
      if (!meta["length"]) { meta["length"] = {} };
      if (!meta["avg"]) { meta["avg"] = {} };
      meta["max_value"][key] = histMax;
      meta["min_value"][key] = histMin;
      meta["na_count"][key] = histNA;
      meta["length"][key] = dataArray[key].length;
      const avg = total / dataArray[key].length;
      meta["avg"][key] = avg;
    });
    return {"meta": meta, "dataJSON": dataJSON, "dataArray": dataArray, "keyIndexDataArray": keyIndexDataArray};
  }
  
  return (
    <DataContext.Provider value={{ 
      dataActive, dataTract, dataPUMA, dataNYC, dataCD, dataBoro, dataSorter, geoJsonDataPUMA, geoJsonDataTract, geoJsonDataGlobe,
      isLoadingTract, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, isLoadingAllTabularData, isLoadingSorter, isLoadingTopology, isLoadingGlobeTopology, error }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
