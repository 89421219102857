// GlobalStore.tsx
import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

type Subscriber<T> = (value: T) => void;

class GlobalStore {
  // INSTANTIATE VARIABLES
  private swarmCalibration:any = {};
  private swarmCalibrationSubscribers: Subscriber<any>[] = [];

  // DEFINE METHODS
  setSwarmCalibration = (config: any) => {
    this.swarmCalibration = config;
    this.swarmCalibrationSubscribers.forEach(sub => sub(this.swarmCalibration));
  };

  // SUBSCRIBE TO CHANGES
  subscribeToSwarmCalibration = (subscriber: Subscriber<any>) => {
    this.swarmCalibrationSubscribers.push(subscriber);
    // Return a function for unsubscribing
    return () => {
      this.swarmCalibrationSubscribers = this.swarmCalibrationSubscribers.filter(sub => sub !== subscriber);
    };
  };

  // GETTERS
  getSetSwarmCalibration = () => (config: any) => {
    this.setSwarmCalibration(config);
  };
}

// INSTANTIATE THE STORE
const globalStore = new GlobalStore();

// EXPORT THE STORE
export const useGlobalSwarmCalibration = () => {
  const [swarmCalibration, setSwarmCalibration] = useState<any>({
    Over100:{
      radius:1,
      padding: 0.25,
      pointDiameter: 0.75,
      collisionDiameter: 0.15,
      stackCount:45,
      testEachPoint: "FALSE",
    },
    Under100:{
      radius:2,
      padding: 0.5,
      pointDiameter: 2,
      collisionDiameter: 1,
      stackCount:45,
      testEachPoint: "FALSE",
    },
    ScatterPlot:{
      radius: 3,
    }
  });
  useEffect(() => {
    const unsubscribe = globalStore.subscribeToSwarmCalibration(setSwarmCalibration);
    return unsubscribe; // Correctly returning the unsubscribe function
  }, []);

  return swarmCalibration;
};

// EXPORT THE STORE METHODS
export const onSetSwarmCalibration = globalStore.getSetSwarmCalibration();
