import React, { useState, useEffect, useMemo, useRef } from 'react';
import * as d3 from "d3";
import { useGlobalSelectedId, onSetActiveIndicator } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';

export interface PieChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass:string;
}

const MARGIN_X = 15;
const MARGIN_Y = 15;

const colors = Colors.wafflePieColors;

const Pie: React.FC<PieChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const selectedId = useGlobalSelectedId();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, []);

  const prepData = () => {
    if (selectedId) {
      const _preppedData = options.variables.map((v: any) => {
        return { name: v.label, value: data.dataJSON[selectedId][v.variable], variable: v.variable };
      });
      setPreppedData(_preppedData);
    }
  };

  const total = preppedData.reduce((acc: number, d: any) => acc + d.value, 0);
  //const radius = Math.min((dimensions.width / 4) - 2 * MARGIN_X, (dimensions.height * .5) - 2 * MARGIN_Y) / 2;
  const chartSize = Math.min(dimensions.width / 2, dimensions.height / 2);
  const radius = chartSize / 2;

  const pie = useMemo(() => {
    const pieGenerator = d3.pie<any, any>().value((d) => d.value);
    return pieGenerator(preppedData);
  }, [preppedData]);

  const arcGenerator = d3.arc();

  const onClicked = (variable: any) => {
    onSetActiveIndicator(variable);
  };

  const shapes = pie.map((grp, i) => {
    const sliceInfo = {
      innerRadius: 0,
      outerRadius: radius + 5,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    const slicePath: any = arcGenerator(sliceInfo);

    return (
      <path
        key={i}
        d={slicePath}
        fill={colors[i]}
        onClick={() => onClicked(grp.data.variable)}
        style={{ cursor: 'pointer' }}
      />
    );
  });

  const fontSize = 12; // For dynamic heights --> // Math.min(12, Math.max(8, dimensions.width / 50));
  const fontSize2 = 12; // For dynamic heights --> // Math.min(10, Math.max(8, dimensions.width / 50));
  const valueWidth = 120; // Width allocated for the value text
  const labelWidth = dimensions.width - radius * 2 - valueWidth - 30;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', paddingRight:"16px" }}>
      <div 
        style={{ 
          marginLeft: '0px', 
          marginRight: '0px', 
          fontSize:fontSize2, 
          lineHeight: `${fontSize2 + 2 }px`,//'1', 
          width: '250px', //`calc(40% - ${(radius + 5)}px)` 
        }}>
        <p
          style={{
            marginRight:"15px",
          }}
        >
          Other information about this stat including the year of origin and relevant noted and maybe an assessment of how typical this percentage is.
          <br /><span style={{ color: '#ccc' }}>Source: Stats United</span>
        </p>
      </div>
      <div 
        style={{ 
          marginRight: '0px', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          //width: radius * 2 + 10,
        }}>
        <svg 
          width={radius * 2 + 10} 
          height={radius * 2 + 10} 
          style={{ 
            display: "inline-block" 
          }}>
          <g
            transform={`translate(${(radius + 5)}, ${(radius + 5)})`}
            className={"container"}
          >
            {shapes}
          </g>
        </svg>
      </div>
      <div 
        style={{ 
          marginLeft: '0px', 
          fontSize, 
          lineHeight: `${fontSize + 2 }px`, //'1',  
          display: 'flex', 
          flexDirection: 'column', 
          width: `calc(100% - ${((radius * 2) + 10)}px - 250px)` 
        }}>
        {preppedData.map((d: any, i: number) => {
          const name = `${d.name} ${Math.round((d.value / total) * 100) === Math.round(d.value) ? "" : `(Error ${Math.round((d.value / total) * 100)}%)`}`;

          return (
            <div key={i} style={{ marginBottom: '5px', cursor: 'pointer', display: 'flex', paddingBottom: '12px' }} onClick={() => onClicked(d.variable)}>
              <div style={{ width: `${valueWidth}px`, fontWeight: 'bold', color: Colors.black, textAlign: 'left', paddingLeft: '5px' }}>
                {d2gRound(d.value)}%
              </div>
              <div style={{ width: `${labelWidth}px`, color: Colors.wafflePieColors[i], textAlign: 'left', whiteSpace: 'normal', overflowWrap: 'break-word', paddingLeft:"5px" }}>
                {name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Pie;
