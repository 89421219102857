import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { ChartCard, ChartCardProps } from './ChartCard'; // Adjust the import path as necessary
import { useInView } from 'react-intersection-observer';
import Colors from '../utilities/Colors'; // Adjust the import path as necessary
import { useGlobalActiveCategory, useGlobalActiveSubCategory, onHoverHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalActiveGeography, useCategoryChangeComplete } from '../../data/StatusStore'; // Adjust the import path as necessary
import { onSetActiveIndicator, onSetBivariateIndicator, onSetActiveGeography, onSetSelectedId, onSetActiveSubCategory, onSetActiveCategory, onSetCategoryChangeComplete } from '../../data/StatusStore';
//import { active, index } from 'd3';
import { debounce } from 'lodash';


interface DataItem {
  id: string;
  value: any;
}

interface ChartIndexProps {
  indicatorOptions: any;
  groupedModules: any;
  allData: any | null;
  chartId: string;
}

// New component to observe each ChartCard
const ObservedChartCard: FC<ChartCardProps & {   
    chartIndicator: any;
    chartIndicatorName: string;
    allData: any |null;
    chartId: string;
    height: string;
    width: string;
    index: number;
    options: any;
    activeGeography: string;
    colorCategory: string;
    //item: any;
    //onSetActiveIndicator: (indicator: string) => void;
    //onSetBivariateIndicator: (indicator: string) => void;
    //activeIndicator : string;
    //bivariateIndicator : string;
    _selectedId: string | null;
    style: React.CSSProperties;
    //persistableSelectedId: string | null;
    }> = ({ index, options, chartId, /*item,*/ allData, activeGeography, _selectedId, style, colorCategory, ...props }) => {
  // Scroll Selection is managed by the ref that is created with useInView below and utilized in the return statement.
  // Uncomment these in order to re-engage this functionality. # scroll on div, scrolling selections, indicator selector
  const { ref, inView } = useInView({
    threshold: 0.5, // Customize this value as needed
    triggerOnce: false, // Ensure it triggers every time it comes into view
  });

  const categoryChangeComplete = useCategoryChangeComplete();
  const selectedSubCategory = useGlobalActiveSubCategory();
  const selectedCategory = useGlobalActiveCategory();
  useEffect(() => {
    if (inView) {
      // Do something when the ChartCard is in view
      console.log('072724 ChartCard in view: props', props);
      console.log('072724 ChartCard in view: options', options);
      console.log("072824 categoryChangeComplete", categoryChangeComplete);
      console.log("072824 onSetCategoryChangeComplete(true);");
      
      if (options.category !== selectedCategory) {
        onSetCategoryChangeComplete(true);
        onSetActiveCategory(options.category);    
      }
      if (options.subCategory !== selectedSubCategory) {
        onSetCategoryChangeComplete(true);
        onSetActiveSubCategory(options.subCategory);
      }
      if (options.subCategory === selectedSubCategory && options.category === selectedCategory) {
        onSetCategoryChangeComplete(false);
      }
      
      //console.log('072724 ChartCard in view: item', item);
      //console.log('072724 ChartCard in view:', props.chartIndicator);
      //if (onSetActiveIndicator) {
        //onSetActiveIndicator(props.chartIndicator);
      //}
    }
  }, [inView, props.chartIndicator]);
  //console.log("N092924 options",options);
  return (
    <div ref={ref} style={style}>
      <ChartCard 
          key={`chart-card-${index}`}
          index={index}
          chartIndicator={options.variables}
          chartIndicatorName={options.breadcrumb}
          allData={allData}
          chartId={chartId}
          height={'200'}
          width={'calc(100% - 0px)'}
          activeGeography={activeGeography}
          /*onSetActiveIndicator={onSetActiveIndicator}
          onSetBivariateIndicator={onSetBivariateIndicator}
          activeIndicator={activeIndicator}
          bivariateIndicator={bivariateIndicator}*/
          options={options}
          _selectedId={_selectedId}
          //persistableSelectedId={persistableSelectedId}
      />
    </div>
  );
}; 

interface NestedJson {
  [category: string]: {
    [subCategory: string]: {
      [module: string]: {
        [group: string]: {
          [shape: string]: any[];
        };
      };
    };
  };
}

const ChartList: FC<ChartIndexProps> = ({
  indicatorOptions, groupedModules, allData, chartId
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [persistableSelectedId, setPersistableSelectedId] = useState<string | null>(null);
  const [flattenedModules, setFlattenedModules] = useState<any | null>(null);
  const [heightsModules, setHeightsModules] = useState<any | null>(null);
  const [listKey, setListKey] = useState(0);
  const listRef = useRef<List>(null);
  const isScrollingRef = useRef(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(0);

  const activeIndicator = useGlobalActiveIndicator();
  const bivariateIndicator = useGlobalBivariateIndicator();
  const activeGeography = useGlobalActiveGeography();
  const selectedId = useGlobalSelectedId();
  const selectedSubCategory = useGlobalActiveSubCategory();
  const selectedCategory = useGlobalActiveCategory();
  const categoryChangeComplete = useCategoryChangeComplete();

  useEffect(() => {

    //onSetSelectedId("36061000900");
    console.log("H092924 indicatorOptions, groupedModules, allData, chartId",indicatorOptions, groupedModules, allData, chartId);

    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight - 81);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight - 81);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    console.log("F092524 groupedModules",groupedModules);
    console.log("A062024 selectedCategory",selectedCategory);
    console.log("A062024 selectedSubCategory",selectedSubCategory);
    /*let filteredModules = {
      [selectedCategory]: {
        [selectedSubCategory]: groupedModules[selectedCategory][selectedSubCategory],
      },
    };*/
    let filteredModules = groupedModules
    console.log("D082924 filteredModules",filteredModules);
    const result = flattenNestedJson(filteredModules);
    console.log("A093024 result",result);
    console.log("D082924 indicatorOptions",indicatorOptions);
    if (result){
      setFlattenedModules(result[0]);
      setHeightsModules(result[1]);
    }
  }, [groupedModules, /* selectedCategory, selectedSubCategory*/ ]);

  useEffect(() => {
    console.log("A062024 groupedModules",groupedModules);
    console.log("A062024 selectedCategory",selectedCategory);
    console.log("A062024 selectedSubCategory",selectedSubCategory);
    //setListKey(prevKey => prevKey + 1); // Update the key to force re-render
  }, [groupedModules, selectedCategory, selectedSubCategory ]);

  useEffect(() => {
    console.log("C082924 groupedModules",groupedModules);
  }, [groupedModules]);
  useEffect(() => {
    console.log("C082924 activeGeography",activeGeography);
  }, [activeGeography]);
  useEffect(() => {
    console.log("C082924 flattenedModules",flattenedModules);
    setListKey(prevKey => prevKey + 1); // Update the key to force re-render
  }, [flattenedModules]);
  useEffect(() => {
    console.log("C082924 listKey",listKey);
  }, [listKey]);
  
  useEffect(() => {
    console.log("060524 <--- selectedId",selectedId);
    console.log("060524 <--- persistableSelectedId",persistableSelectedId);
    console.log("060524 <--- activeGeography",activeGeography);
    if (selectedId) {
      setPersistableSelectedId(selectedId);
    }  
  }, [selectedId]);

  const flattenNestedJson = (nestedJson: NestedJson): any => {
    const flatArray: any = [];
    const heightsArray: any = [];
    let runningHeight = 0;
    let runningIndex = 0;
    console.log("D082924 nestedJson",nestedJson);
    Object.keys(nestedJson).forEach(category => {
      const subCategories = nestedJson[category];
      Object.keys(subCategories).forEach(subCategory => {
        const modules = subCategories[subCategory];
        Object.keys(modules).forEach(module => {
          const groups = modules[module];
          Object.keys(groups).forEach(group => {
            const shapes = groups[group];
            Object.keys(shapes).forEach(shape => {
              const dataArray = shapes[shape];
              //console.log("072724 dataArray",dataArray);
              //console.log("072724 activeGeography",activeGeography);
              //console.log("072724 activeGeography.toUpperCase()",activeGeography.toUpperCase());
              let allDataPresent = true;
              const transformedDataArray: any = dataArray.map((data: any, index: number) => {
                console.log("E093024 data",data);
                //console.log("A093024 activeGeography",activeGeography);
                //console.log("A093024 data[activeGeography.toUpperCase()] ",data[activeGeography.toUpperCase()] );
                if (data[activeGeography.toUpperCase()] === "X") {
                  allDataPresent = false;
                }
                return ({
                  label: data.DISPLAY_NAME,
                  variable: data.VARIABLE_NAME ? data.VARIABLE_NAME : data.variable,
                  type: data.Shape,
                  sort: data,
                  index: index,
                  geo: activeGeography,
                  category: category,
                })
              });
              //if (allDataPresent){
               
                const _object = {
                  allDataPresent: allDataPresent,
                  variables: transformedDataArray,
                  type: transformedDataArray[0].type,
                  breadcrumb: `${category} > ${subCategory} > ${module} > ${group} > ${shape}`, 
                  category: category,
                  subCategory: subCategory,
                } 
                //console.log("D082924 getItemSizeWithObject(_object)",getItemSizeWithObject(_object));
                runningHeight = runningHeight + getItemSizeWithObject(_object);
                
                heightsArray.push({
                  runningHeight: runningHeight,
                  runningIndex: runningIndex,
                  breadcrumb: _object.breadcrumb,
                  type: _object.type,
                });
                runningIndex = runningIndex + 1;
                flatArray.push(_object);
              //}
            });
          });
        });
      });
    });
    console.log("072724 flatArray", flatArray);
    return [flatArray, heightsArray];
  };

  const updateSelectedId = debounce((index: number) => {
    if (flattenedModules && flattenedModules[index]) {
      const item = flattenedModules[index];
      console.log("A060224 item",item);
      console.log("060524 ---> selectedId",selectedId);
      console.log("060524 ---> persistableSelectedId",persistableSelectedId);
      if (item && item.variables && item.variables[0]) {
        if (selectedId){
          console.log("060524 ------------> onSetSelectedId selectedId",selectedId);
          onSetSelectedId(selectedId);
        }else{
          if (persistableSelectedId){
            console.log("060524 ------------> onSetSelectedId persistableSelectedId",persistableSelectedId);
            onSetSelectedId(persistableSelectedId);
          }
        }
      }
    }
  }, 300); // Debounce interval set to 300ms

  const scrollToCategory = () => {
    console.log("F082924 selectedCategory",selectedCategory);
    console.log("F082924 selectedSubCategory",selectedSubCategory);
    if (flattenedModules) {
      const index = flattenedModules.findIndex(
        (item: any) => item.category === selectedCategory && item.subCategory === selectedSubCategory
      );
      if (index >= 0 && listRef.current) {
        isScrollingRef.current = true;
        listRef.current.scrollToItem(index, 'start');
        //setTimeout(() => {
        isScrollingRef.current = false;
        console.log("072824 onSetCategoryChangeComplete(false);");
        onSetCategoryChangeComplete(false);
        //}, 500); // Allow some time for the scroll to complete
      }else{
        console.log("072824 onSetCategoryChangeComplete(false);");
        onSetCategoryChangeComplete(false);
      }
    }
  };

  useEffect(() => {
    console.log("072824 categoryChangeComplete",categoryChangeComplete);
    console.log("072824 isScrollingRef.current",isScrollingRef.current);
    if (!isScrollingRef.current && !categoryChangeComplete) {
      scrollToCategory();
    }else{
      console.log("072824 onSetCategoryChangeComplete(false);");
      onSetCategoryChangeComplete(false);
    }
  }, [selectedCategory, selectedSubCategory]);

  const renderRow = useMemo(() => {
    console.log("F092524 indicatorOptions",indicatorOptions)
    //console.log("A041524 allData",allData)
    console.log("F092524 activeGeography",activeGeography)
    return ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const item = flattenedModules[index];
      //console.log("N092924 item",item)
      if (!item.allDataPresent) {
        return null;
      }
      return (
          <ObservedChartCard  /* ChartCard */
            key={`chart-card-${index}`}
            index={index}
            chartIndicator={item.variables}
            chartIndicatorName={item.breadcrumb}
            allData={allData}
            chartId={chartId}
            height={'200'}
            width={'calc(100% - 0px)'}
            activeGeography={activeGeography}
            colorCategory={item.category}
            /*onSetActiveIndicator={onSetActiveIndicator}
            onSetBivariateIndicator={onSetBivariateIndicator}
            activeIndicator={activeIndicator}
            bivariateIndicator={bivariateIndicator}*/
            options={item}
            _selectedId={selectedId}
            //item={item}
            style={style}
            //persistableSelectedId={persistableSelectedId}
          />
      );
    };
  }, [flattenedModules, allData, chartId, activeGeography/*, selectedId*/]);

  const handleScroll = debounce((scrollOffset: number) => {
    setScrollOffset(scrollOffset); // Save the current scroll offset
    //console.log("E082924 scrollOffset",scrollOffset);
    //console.log("E082924 heightsModules",heightsModules);
    const filtered = heightsModules?.find((h: any) => {
      //console.log("E082924 h",h);
      return h.runningHeight > scrollOffset
    });
    //console.log("E082924 filtered",filtered);
    if (filtered){
      setScrollIndex(filtered?.runningIndex);
    }    
    //console.log("E082924 (old) selectedId",selectedId);
    //const itemIndex = Math.floor(scrollOffset / 200); // Calculate item index based on scroll offset and item height
    //console.log("E082924 (new) selectedId itemIndex",itemIndex);
    //updateSelectedId(itemIndex); // fallback logic based on a stanndard height of 200px
    
  }, 30); // Debounce interval set to 300ms

  const getItemSize = (index:number) => {
    //console.log("C082924 index",index);
    //console.log("C082924 flattenedModules[index]",flattenedModules[index]);
    //console.log("A061224 flattenedModules[index].type",flattenedModules[index].type);
    const _object = flattenedModules[index];
    return getItemSizeWithObject(_object);
  }
  const getItemSizeWithObject = (_object:any) => {
    if (!_object.allDataPresent) {
      return 0;
    }
    let defaultHeight = 250; //300;
    //console.log("A083024 _object",_object);
    switch (_object.type.toLowerCase()) {
      case 'histogram':
        return defaultHeight;
      case 'beeswarm':
        return (_object.variables.length * 100) + 100;
      case 'pie':
        return defaultHeight;
      case 'waffle':
        return defaultHeight;
      case 'list':
        return defaultHeight;
      case 'ranked list':
        return defaultHeight;
      case 'pyramid':
        return ((_object.variables.length / 3) * 25) + 10; // defaultHeight;
      case 'stacked histograms':
        return (_object.variables.length * 100) + 100; // defaultHeight;
      case 'map':
        return 200; // defaultHeight;
      case 'deviation from average':
        return (_object.variables.length * 40) + 70;
      default:
        return defaultHeight;
    }
  }
  /*useEffect(() => {
      if (listRef.current) {
          setScrollOffset(listRef.current.state.scrollOffset); // Save the current scroll offset
      }
  }, [activeGeography, flattenedModules]); // Run this effect when the key dependencies change*/

  useEffect(() => {
      if (listRef.current) {
        console.log("F082924 scrollOffset",scrollOffset);
        let _scrollOffset = scrollOffset;
        if (scrollIndex && scrollOffset && heightsModules){
          console.log("F082924 scrollIndex",scrollIndex);
          console.log("F082924 heightsModules",heightsModules);
          console.log("F082924 heightsModules[scrollIndex]",heightsModules[scrollIndex]);
          _scrollOffset = heightsModules[scrollIndex].runningHeight;
        }
        listRef.current.scrollTo(_scrollOffset); // Restore the saved scroll offset
      }
  }, [listKey]); // Restore scroll after re-render based on the new listKey

  return (
    <div ref={containerRef} style={{ height: '100%' }}>
      <List
        key={listKey} // Adding a key to force re-render when flattenedModules changes
        height={containerHeight}
        width="100%"
        itemCount={flattenedModules ? flattenedModules.length : 0}
        itemSize={getItemSize} // The height of each row in pixels
        style={{ overflowX: 'hidden' }}
        onScroll={({ scrollOffset }) => handleScroll(scrollOffset)}
        ref={listRef}
      >
        {renderRow}
      </List>
    </div>
  );
};

export default ChartList;
