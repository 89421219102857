import React, { FC, ChangeEvent } from 'react';

interface Option {
  label: string;
  value: string | number;
}

interface SelectorProps {
  options: Option[];
  onChange: (value: string) => void;
  placeholder?: string;
}

export const Selector: FC<SelectorProps> = ({ options, onChange, placeholder }) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <select onChange={handleChange} defaultValue="">
      {placeholder && <option value="" disabled>{placeholder}</option>}
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
